@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes run {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translate(100%);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(359deg)
	}
}
