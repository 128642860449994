.SectionNav {
	@include clearfix;

	> .SectionNav-item {
		float: left;

		> .SectionNav-button {
			padding-right: ms(0.25em);
			display: inline-block;
			vertical-align: top;

			> .SectionNav-label {
				top: em(-3, 12);
				font-size: em(12, 18);
				@include bp(medium) {
					top: em(-4, 14);
					font-size: em(14, 20);
				}
				@include bp(larger) {
					top: em(-5, 14);
					font-size: em(14, 22);
				}
			}
		}

		&::after {
			content: "/";
			position: relative;
			display: inline-block;
			vertical-align: top;
			top: em(9, 12);
			font-size: em(12, 18);
			font-style: normal;
			font-weight: 400;
			line-height: 1.25;
			@include bp(medium) {
				top: em(8, 14);
				font-size: em(14, 20);
			}
			@include bp(larger) {
				top: em(9, 14);
				font-size: em(14, 22);
			}
		}

		&:last-child {
			@include bp(smaller) {
				padding-right: ms(0.5em);
			}

			> .SectionNav-button {
				padding-right: ms(0.5em);

				> .SectionNav-label {
					top: em(-2, 14);
					font-family: $font-extended;
					font-weight: 700;
					font-size: em(14, 18);
					padding-bottom: em(4, 14);
					background-image: linear-gradient(to bottom, transparent unit(19, 21), currentColor unit(19, 21));
					@include bp(medium) {
						top: em(-3, 14);
						font-size: em(15.5, 20);
						padding-bottom: em(4.5, 15.5);
						background-image: linear-gradient(to bottom, transparent unit(21.5, 23.5), currentColor unit(21.5, 23.5));
					}
					@include bp(larger) {
						top: em(-3, 14);
						font-size: em(17, 22);
						padding-bottom: em(5, 17);
						background-image: linear-gradient(to bottom, transparent unit(23, 25), currentColor unit(23, 25));
					}
				}
			}

			&::after {
				display: none;
				content: "";
			}
		}
	}
}

.SectionNav-items,
.SectionNav-item {
	display: inline-block;
	vertical-align: top;
}

.SectionNav-button {
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	// Reset unusual Firefox-on-Android default style;
	// see https://github.com/necolas/normalize.css/issues/214
	background-image: none;
	border: none;
	font: inherit;
	line-height: 1.25;
	text-align: inherit;
	white-space: nowrap;
	outline: 0;

	display: block;
	transition-duration: 200ms;
	transition-property: background-color, color;
	transition-timing-function: ease-out;
	padding: em(6.5, 18) ms(0.5em);
	@include bp(medium) {
		padding-top: em(5.5, 20);
		padding-bottom: em(5.5, 20);
	}
	@include bp(larger) {
		padding-top: em(4.5, 22);
		padding-bottom: em(4.5, 22);
	}

	&:hover {
		color: $color-secondary;
	}
}

.SectionNav-label {
	position: relative;
	vertical-align: baseline;
	font-size: em(14, 18);
	font-style: normal;
	font-weight: 400;
	line-height: 1.25;
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}
}

.SectionNav-item--dropdown {
	position: relative;
	z-index: 1;

	> .SectionNav-button {
		position: relative;
		z-index: 1;
		margin-right: 0;
		color: $color-secondary;
	}

	.SectionNav-icon {
		position: relative;
		display: inline-block;
		line-height: 1.25;
		top: em(1, 18);
		@include bp(medium) {
			top: 0;
		}
		@include bp(larger) {
			top: em(-1, 22);
		}
	}

	> .SectionNav-items {
		position: absolute;
		z-index: 0;
		top: em(-12, 18);
		left: 0;
		min-width: 100%;
		display: none;
		background-color: $color-white;
		box-shadow: 0 0 ms(0.5em) transparentize($color-primary, 0.8);
		padding-top: em(54, 18);
		padding-bottom: em(12, 18);
		@include bp(smaller) {
			left: ms(-0.25em);
			padding-bottom: em(12, 18);
		}
		@include bp(medium) {
			top: em(-18, 20);
			padding-top: em(60, 20);
			padding-bottom: em(18, 20);
		}
		@include bp(larger) {
			top: em(-18, 22);
			padding-top: em(60, 22);
			padding-bottom: em(18, 22);
		}

		.Theme--black &,
		.ThemeBlock--black & {
			background-color: $color-primary;
		}

		> .SectionNav-item {

			> .SectionNav-button {
				padding: em(12.75, 18) ms(0.5em);
				transition-duration: 200ms;
				transition-property: background-color, color;
				transition-timing-function: ease-out;
				@include bp(smaller) {
					padding-left: ms(0.75em);
					padding-right: ms(0.75em);
				}
				@include bp(medium) {
					padding-top: em(11.5, 20);
					padding-bottom: em(11.5, 20);
				}
				@include bp(larger) {
					padding-top: em(10.25, 22);
					padding-bottom: em(10.25, 22);
				}

				> .SectionNav-label {
					vertical-align: baseline;
				}
			}
		}
	}

	.SectionNav-item {
		float: none;
		display: block;
	}

	&.isExpanded {

		.SectionNav-icon {
			top: 0;
			transform: rotate(180deg);
			@include bp(medium) {
				top: em(-2, 20);
			}
			@include bp(larger) {
				top: em(-2, 22);
			}
		}

		> .SectionNav-items {
			display: block;
		}
	}
}
