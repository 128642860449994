.Caption {

	> p,
	.Caption-body {
		hyphens: auto;
		font-size: em(15, 18);
		@include bp(medium) {
			font-size: em(16, 20);
		}
		@include bp(larger) {
			font-size: em(16, 22);
		}

		a {
			background-image: linear-gradient(to bottom, transparent unit(18, 19), $color-secondary unit(18, 19));
			text-decoration: none;
			transition-duration: 200ms;
			transition-property: background-color, color;
			transition-timing-function: ease-out;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			.Theme--red &,
			.ThemeBlock--red & {
				background-image: linear-gradient(to bottom, transparent unit(18, 19), $color-primary unit(18, 19));
			}

			&:hover,
			&:focus,
			&:active {
				outline: 0;
				color: $color-secondary;

				.Theme--red &,
				.ThemeBlock--red & {
					color: $color-primary;
				}
			}

			&:active {
				transition-duration: 0ms;
			}
		}
	}
}

.Caption--small {

	> p,
	.Caption-body {
		font-size: em(12, 18);
		@include bp(medium) {
			font-size: em(14, 20);
		}
		@include bp(larger) {
			font-size: em(14, 22);
		}
	}
}

.Caption-credit {

}
