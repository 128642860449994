.Logo {
	position: relative;
}

.Logo-eagle {
	position: absolute;
	top: em(17, 22);
	left: 0;
	right: 0;
	margin: 0 auto;
	width: em(72, 22);
	height: em(72, 22);
	z-index: 1;
	transform: translateZ(0);

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;

		.Theme--black & {
			fill: $color-white;
		}

		.Theme--red & {
			fill: $color-primary;
		}
	}
}

.Logo-lettering {
	position: relative;
	z-index: 0;
	padding-bottom: em(36, 22);
	color: $color-secondary;
	font-family: $font-expanded;
	font-weight: 900;
	text-align: center;
	transform: translateZ(0);
	white-space: nowrap;

	.Theme--black & {
		color: $color-secondary;
	}

	.Theme--red & {
		color: $color-white;
	}
}

.Logo-lettering-small {
	display: block;
	font-size: em(18, 22);
	line-height: 1;
}

.Logo-lettering-large {
	display: block;
	margin-top: em(-2, 16);
	font-size: em(36, 22);
	line-height: 1;
}

.Logo-lettering-large-first {
	padding-left: em(18, 36);
}

.Logo-lettering-large-first + .Logo-lettering-large-second {
	margin-left: em(36, 36);
}
