.Billboard {
	position: relative;
	padding-bottom: 100%;
	@include bp(medium) {
		padding-bottom: 75%;
	}
	@include bp(large) {
		padding-bottom: 66.66666%;
	}
	@include bp(larger) {
		padding-bottom: unit(9, 16);
	}
}

.Billboard-visual {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

a.Billboard,
a.Billboard-visual-inner {
	display: block;
}

.Billboard-header,
.Billboard-body {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
}

.Billboard-heading,
.Billboard-cta {
	padding: 0 ms(0.5em);
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(larger) {
		margin-left: 16.66666%;
		margin-right: 16.66666%;
	}
}

.Billboard-heading {
	color: $color-secondary;
}

.Billboard-meta,
.Billboard-cta {
	margin-top: ms(1em);
}

.Billboard--small {
	@include bp(medium) {
		padding-bottom: 100%;
	}

	.Billboard-heading,
	.Billboard-cta {
		@include bp(small) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.Billboard--composing {

	.Billboard-header {
		display: none;
		top: 0;
		transform: none;
	}

	.Billboard-body {
		top: auto;
		bottom: ms(0.5em);
		transform: none;
		@include bp(smaller) {
			bottom: ms(1em);
		}
		@include bp(medium) {
			bottom: ms(1.5em);
		}
	}
}

.Billboard--split {
	padding-bottom: 0;

	.Billboard-visual {
		@include bp(null, medium) {
			display: none;
		}
	}

	.Billboard-body {
		position: relative;
		top: auto;
		transform: none;
		text-align: inherit;

	}

	.Billboard-body-inner {
		padding-top: ms(0.5em);
		padding-bottom: ms(0.5em);
		@include bp(smaller) {
			padding-top: ms(0.75em);
			padding-bottom: ms(0.75em);
		}
		@include bp(small) {
			padding-top: ms(1em);
			padding-bottom: ms(1em);
		}
		@include bp(medium) {
			@include clearfix;
			overflow: hidden;
			padding-top: ms(1.5em);
			padding-bottom: ms(1.5em);
		}
		@include bp(larger) {
			padding-top: ms(2em);
			padding-bottom: ms(2em);
		}
		@include bp(huge) {
			padding-top: ms(4em);
			padding-bottom: ms(4em);
		}

		&::before {
			@include bp(medium) {
				content: "";
				display: block;
				padding-bottom: 100%;
				float: left;
				margin-left: -100%;
				margin-top: ms(-1.5em);
				margin-bottom: ms(-1.5em);
			}
			@include bp(larger) {
				margin-top: ms(-2em);
				margin-bottom: ms(-2em);
			}
		}
	}

	&.Billboard--left {

		.Billboard-visual {
			@include bp(medium) {
				left: 50%;
			}
		}

		.Billboard-body {
			@include bp(medium) {
				margin-right: 50%;
			}
		}
	}

	&.Billboard--right {

		.Billboard-visual {
			@include bp(medium) {
				right: 50%;
			}
		}

		.Billboard-body {
			@include bp(medium) {
				margin-left: 50%;
			}
		}
	}
}

.Billboard--tour {
	overflow: hidden;

	.Billboard-visual {
		@include bp(null, medium) {
			position: relative;
			display: block;
			padding-top: unit(9, 16);
		}
	}

	.Billboard-visual-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&.Billboard--left {

		.Billboard-body-inner {
			@include bp(medium) {
				padding-right: ms(1em);
			}
			@include bp(larger) {
				padding-right: ms(1.5em);
			}
		}
	}

	&.Billboard--right {

		.Billboard-body-inner {
			@include bp(medium) {
				padding-left: ms(1em);
			}
			@include bp(larger) {
				padding-left: ms(1.5em);
			}
		}
	}
}
