.Form {

}

.Form-group {
	padding: 0 0.75em;

	& + & {
		margin-top: 1em;
	}
}

// Honey pot field
.Form-group--confirm {
	display: none;
}

.Form-label {
	padding-bottom: em(10.5, 15);
	display: block;
	font-size: em(15, 18);
	line-height: 1.25;
	@include bp(medium) {
		padding-bottom: em(9, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	.hasError & {
		color: $color-secondary;
	}
}

.Form-label-required {
	margin-left: 0.5em;
	color: $color-secondary;
}

.Form-control {
	display: block;
	width: 100%;
	height: em(48, 18);
	padding: em(10.5, 18) em(13.5, 18);
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	vertical-align: middle;
	background-color: $color-off-white;
	background-image: none;
	border: none;
	border-radius: 0;
	transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
	appearance: none;
	@include bp(medium) {
		height: em(48, 20);
		padding: em(9, 20) em(15, 20);
	}
	@include bp(larger) {
		height: em(48, 22);
		padding: em(7.5, 22) em(16.5, 22);
	}

	&:focus {
		outline: 0;
	}

	.hasError & {
		border-color: $color-secondary;

		&:focus {
			//background-color: rgb(169,68,66);
		}
	}
}

textarea.Form-control {
	height: auto;
}

.Form-field {
	position: relative;
	display: block;
	padding-top: 0.75em;

	.Form-label {
		position: absolute;
		left: ms(0.5em, 0.667);
		top: ms(1.375em, 0.667);
		padding: 0 ms(0.25em, 0.667);
		color: $color-medium-gray;
		background-color: $color-white;
		font-size: em(12, 18);
		@include bp(medium) {
			font-size: em(14, 20);
		}
		@include bp(larger) {
			font-size: em(14, 22);
		}

		.isFocused &,
		.isFilled & {
			top: ms(0.0625em, 0.667);
			@include bp(medium) {
				top: ms(0.125em, 0.667);
			}
		}

		.isFocused & {
			color: inherit;
		}

		.hasError & {
			color: $color-secondary;
		}
	}

	.Form-control {

	}
}

.Form-options {
	color: $color-medium-gray;

	.Form-group--privacyPolicyConsent & {
		color: inherit;
	}
}

.Form-help {
	margin-top: 0.5em;
	font-size: em(15, 18);
	line-height: 1.5;
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	.hasError & {
		color: $color-secondary;
	}
}

.Form--inline {
	padding: 0 0.75em;

	.Form-field {
		padding-top: 0;
		overflow: hidden;
	}

	.Form-control {
		@include bp(medium) {
			height: em(54, 20);
			padding: em(12, 20) em(15, 20);
		}
		@include bp(larger) {
			height: em(60, 22);
			padding: em(13.5, 22) em(16.5, 22);
		}
	}

	.Form-submit {
		float: right;
	}
}

.Form--newsletter {
	@include clearfix;

	.Form-group--firstname,
	.Form-group--lastname {
		margin-bottom: 0.75em;
		float: left;
		width: 50%;
	}

	.Form-group--firstname + .Form-group--lastname {
		margin-top: 0;
	}

	.Form-group--email {
		clear: left;
	}

	.Form-group--submit {
		text-align: center;
	}
}
