.Event {
	@include bp(large) {
		@include clearfix;
	}

	& + & {
		margin-top: ms(1em);
		@include bp(large) {
			margin-top: ms(0.5em);
		}
	}
}

.Event-header,
.Event-body,
.Event-footer {
	padding: 0 ms(0.5em);
	@include bp(large) {
		float: left;
	}
}

.Event-header {
	@include bp(large) {
		width: 20.83333%;
		margin-left: 20.83333%;
	}
	@include bp(larger) {
		width: 20%;
		margin-left: 30%;
	}
	@include bp(huge) {
		width: 16.66666%;
		margin-left: 33.33333%;
	}
	@include bp(ultra) {
		width: 14.28571%;
		margin-left: 42.85714%;
	}
}

.Event-body {
	@include bp(large) {
		width: -58.33333%;
	}
	@include bp(larger) {
		width: 50%;
	}
	@include bp(huge) {
		width: 41.66666%;
		margin-right: 8.33333%;
	}
	@include bp(ultra) {
		width: 28.57142%;
		margin-right: 7.14285%;
	}
}

.Event-footer {
	padding-top: ms(0.3em);
	@include bp(large) {
		width: 20.83333%;
		margin-left: -100%;
	}
	@include bp(larger) {
		width: 20%;
		margin-left: -90%;
	}
	@include bp(huge) {
		width: 16.66666%;
		margin-left: -83.33333%;
	}
	@include bp(ultra) {
		width: 14.28571%;
		margin-left: -64.28571%;
	}

	* + & {
		@include bp(null, medium) {
			margin-top: ms(0.2em);
		}
	}
}

.Event-title {

}

.Event-date,
.Event-label {
	font-family: $font-extended;
	font-size: 1.3em;
	font-weight: 700;
	line-height: 1.3;
}

.Event-countryCode,
.Event-description,
.Event-weekDay {
	font-size: em(15, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}
}

.Event-countryCode {
	margin-left: 0.25em;
}

.Event-description {

	a {
		text-decoration: underline;
	}
}

.Event-weekDay {
	@include bp(large) {
		display: block;
	}
}

.Event-status-text {
	display: block;
	//padding-top: em(24 - 9.75, 14);
	font-size: em(14, 18);
	@include bp(null, medium) {
		margin-top: ms(-0.5em, 14/18);
	}
	@include bp(medium, large) {
		margin-top: ms(-0.3em, 14/18);
	}
	@include bp(large) {
		//padding-top: em(22 - 10, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		//padding-top: em(23 - 10, 16);
		font-size: em(16, 22);
	}
}

.Event--broadcast {
	color: $color-white;

	.Event-date,
	.Event-label {
		font-size: 1em;
		line-height: 1.5;
	}
}

.Event--shortlist {
	@include bp(medium, large) {
		@include clearfix;
	}

	.Event-header,
	.Event-body {
		@include bp(medium, large) {
			float: left;
		}
	}

	.Event-header {
		@include bp(medium, large) {
			width: 33.33333%;
		}
	}

	.Event-body {
		@include bp(medium, large) {
			width: unti(2, 3);
		}
	}

	.Event-weekDay,
	.Event-description,
	.Event-footer {
		@include bp(null, large) {
			display: none;
		}
	}

	.Event-date,
	.Event-label {
		font-family: $font-expanded;
		font-weight: 900;
	}
}
