.Masthead {
	padding: ms(0.75em) 0 0;
	@include bp(medium) {
		padding-top: ms(1em);
	}
	@include bp(large) {
		padding-top: ms(0.5em);
	}
}

.Masthead-body {
	text-align: center;
	@include bp(null, large) {
		position: relative;
	}
	@include bp(null, medium) {
		padding-left: em(48, 18);
		padding-right: em(48, 18);
	}
	@include bp(medium, large) {
		padding-left: em(54, 20);
		padding-right: em(54, 20);
	}
}

.Masthead-logo {
	display: inline-block;
	vertical-align: top;
	@include bp(null, medium) {
		font-size: 0.747em;
	}
}

.Masthead-logo--b {
	@include bp(medium) {
		position: relative;
		top: em(-22, 22);
	}
}

.Masthead-button {
	border: none;
	padding: em(14, 16);
	height: em(48, 16);
	display: block;
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	background-image: none;
	font-family: inherit;
	font-size: em(16, 18);
	line-height: 1.25;
	@include bp(medium) {
		padding: em(17, 16);
		height: em(54, 16);
		font-size: em(16, 20);
	}
	@include bp(large) {
		display: none;
	}

	&,
	&:active,
	&:hover,
	&:focus,
	&:visited {
		outline: 0;
		text-decoration: none;
	}
}

.Masthead-button--menu,
.Masthead-button--search {
	position: absolute;
	top: em(-3, 16);
	@include bp(medium) {
		top: em(5, 16);
	}
}

.Masthead-button--menu {
	left: 0;
	@include bp(smaller, large) {
		left: ms(-0.125em);
	}
}

.Masthead-button--search {
	right: 0;
}
