.Filter {
	@include clearfix;
	padding: 0 ms(0.25em);
	@include bp(null, medium) {
		padding-top: ms(0.25em);
		padding-bottom: ms(0.25em);
	}
	@include bp(medium) {
		display: inline-block;
		vertical-align: top;
		text-align: left;
	}
}

.Filter-unit {
	padding: 0 ms(0.25em);
	float: left;
	@include bp(null, medium) {
		padding-top: ms(0.25em);
		padding-bottom: ms(0.25em);
		width: 50%;
	}
	@include bp(medium) {
		width: em(220, 20);
	}
}

.Filter-unit--submit {
	@include bp(null, medium) {
		width: 100%;
	}
	@include bp(medium) {
		width: auto;
	}

	.js & {
		display: none;
	}
}

.Filter-group {
	position: relative;
}

.Filter-group-label {
	position: relative;

	.Filter-group.hasValue & {

	}

	.Filter-group.isExpanded & {

	}
}

.Filter-group-label,
.Filter-submit {

	.Button {
		display: block;
		width: 100%;
	}
}

.Filter-group-controls {
	display: none;
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	margin-top: em(1, 18);
	min-width: 100%;
	background: $color-primary;
	color: $color-white;
	@include bp(medium) {
		margin-top: em(1, 20);
	}
	@include bp(larger) {
		margin-top: em(1, 22);
	}

	.Theme--black & {
		background: $color-off-white;
		color: $color-primary;
	}

	.csstransforms3d.csstransitions & {
		display: block;
		opacity: 0;
		transform: translateY(ms(-2.25em));
		visibility: hidden;
		transition: visibility 0ms 150ms linear, transform 150ms ease-out, opacity 150ms ease-out;
	}

	.Filter-group.isExpanded & {
		display: block;

		.csstransforms3d.csstransitions & {
			opacity: 1;
			transform: translateY(0);
			visibility: visible;
			transition-delay: 0ms;
		}
	}
}
