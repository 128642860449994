.Root {
	position: relative;
}

.Root-masthead {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	right: 0;
	@include bp(large) {
		padding-top: em(60, 20);
		z-index: 2;
	}
	@include bp(larger) {
		padding-top: em(60, 22);
	}
}

.Root-menu {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	right: 0;
	background-color: $color-white;
	transform: translateZ(0);
	@include bp(null, large) {
		position: fixed;
		z-index: 2;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		opacity: 0;
		visibility: hidden;
		transition: visibility 0ms linear 200ms, opacity 200ms ease-out;
	}
	@include bp(large) {
		position: fixed;
	}

	.isMenuOpen & {
		@include bp(null, large) {
			opacity: 1;
			visibility: visible;
			transition-delay: 0ms;
		}
	}

	&::after {
		@include bp(large) {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			box-shadow: 0 0 ms(0.75em) transparentize($color-primary, 0.8);
			transform: translateZ(0);
			opacity: 0;
		}
	}

	.isBelowMenu.isMenuVisible &::after {
		@include bp(large) {
			transition: opacity 300ms ease-out;
			opacity: 1;
		}
	}

	.Theme--black & {
		background-color: $color-primary;
	}

	.Theme--red & {
		background-color: $color-secondary;
	}
}

.Root-main {
	position: relative;
	z-index: 1;
}

.Root-footer {
	position: relative;
	z-index: 0;
}

.Root-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
	background-color: $color-primary;
	color: $color-off-white;
	display: none;
	opacity: 0;

	.Root.isOverlayOpen & {
		display: block;
		opacity: 1;
	}
}

.Root-indicator {
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	right: 0;
	height: em(4, 18);
	display: none;
	transform: translateZ(0);
	@include bp(medium) {
		height: em(4, 20);
	}
	@include bp(larger) {
		height: em(4, 22);
	}

	.isLoading & {
		display: block;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		animation: run 1000ms infinite linear;
		background-image: linear-gradient(to right, transparentize($color-secondary, 1), $color-secondary);
		display: block;
	}
}

.Root-up {
	position: fixed;
	z-index: 4;
	right: ms(0.25em);
	bottom: ms(1.5em);
	transform: translateZ(0);
	opacity: 0;
	visibility: hidden;
	transition: visibility 0ms linear 200ms, opacity 200ms ease-out;
	@include bp(smaller) {
		right: ms(0.5em);
	}
	@include bp(medium) {
		right: ms(0.5em);
		bottom: ms(0.5em);
	}

	.isBelowMenu.isMenuVisible & {
		opacity: 1;
		visibility: visible;
		transition-delay: 0ms;
	}
}
