.Option {
	display: block;
	padding: em(15.25, 14) em(21, 14) em(15.25, 14) em(45, 14);
	font-size: em(14, 18);
	line-height: 1.25;
	cursor: pointer;
	@include bp(medium) {
		padding: em(14, 16) em(24, 16) em(14, 16) em(48, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	input[type="checkbox"],
	input[type="radio"] {
		cursor: pointer;
		float: left;
		margin-top: 0.1em;
		margin-left: -1.375em;
	}
}

.Option-label {

	a {
		color: $color-primary;
		text-decoration: underline;
	}
}

.Option:not(.__foo__) {

	input[type="checkbox"],
	input[type="radio"] {
		opacity: 0;
	}

	.Option-ui {
		position: relative;
		cursor: pointer;
		display: block;
		float: left;
		margin-top: em(3, 14);
		margin-left: em(-24, 14);
		width: em(12, 14);
		height: em(12, 14);
		@include bp(medium) {
			margin-top: em(3, 16);
			margin-left: em(-24, 16);
			width: em(14, 16);
			height: em(14, 16);
		}

		&::before,
		&::after {
			position: absolute;
			content: "";
			display: block;
			top: 50%;
			left: 0;
			width: em(13, 14);
			height: em(1, 14);
			transform-origin: center;
			background-color: currentColor;
			opacity: 0;
			@include bp(medium) {
				width: em(15, 16);
				height: em(1, 16);
			}
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}

	.Option-label {
		opacity: 0.75;
	}

	input[type="radio"] ~ .Option-ui {
		border-radius: 50%;

		&::after {
			border-radius: 50%;
		}
	}
}

.Option--agree {
	padding-bottom: 0;
	padding-left: em(24, 14);
	padding-right: 0;
	padding-top: 0;
	@include bp(medium) {
		padding-left: em(24, 16);
	}

	&:not(.__foo__) {

		.Option-ui {
			border: em(1, 14) solid;
			width: em(15, 14);
			height: em(15, 14);
			margin-top: em(2, 14);
			@include bp(medium) {
				border-width: em(1, 16);
				margin-top: em(2, 16);
				width: em(17, 16);
				height: em(17, 16);
			}
		}

		.Option-label {
			opacity: 1;
		}
	}
}

.Option input[type="checkbox"]:checked,
.Option input[type="radio"]:checked {

	& ~ .Option-ui {

		&::before,
		&::after {
			opacity: 1;
		}

		&::after {
			background-color: currentColor;
		}
	}

	& ~ .Option-label {
		opacity: 1;
	}
}

.OptionGroup {
	padding: em(15.25, 18) 0;
	@include bp(medium) {
		padding-top: em(14, 20);
		padding-bottom: em(14, 20);
	}
	@include bp(large) {
		padding-top: em(14, 22);
		padding-bottom: em(14, 22);
	}
}
