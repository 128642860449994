.Excerpt {

	> p {
		hyphens: auto;
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(21, 22), $color-secondary unit(21, 22));
		text-decoration: none;
		transition-duration: 200ms;
		transition-property: background-color, color;
		transition-timing-function: ease-out;
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		.Theme--red &,
		.ThemeBlock--red & {
			background-image: linear-gradient(to bottom, transparent unit(21, 22), $color-primary unit(21, 22));
		}

		&:hover,
		&:focus,
		&:active {
			outline: 0;
			color: $color-secondary;

			.Theme--red &,
			.ThemeBlock--red & {
				color: $color-primary;
			}
		}

		&:active {
			transition-duration: 0ms;
		}
	}
}

.Excerpt--small {

	> p {
		font-size: em(15, 18);
		@include bp(medium) {
			font-size: em(16, 20);
		}
		@include bp(larger) {
			font-size: em(16, 22);
		}
	}
}

.Excerpt--large {

	> p {
		font-size: 1.3em;
		line-height: 1.3;
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-secondary unit(28, 29));

		.Theme--red &,
		.ThemeBlock--red & {
			background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-primary unit(28, 29));
		}
	}
}

.Excerpt--larger {

	> p {
		font-size: 1.3em;
		line-height: 1.3;
		@include bp(medium) {
			font-size: em(36, 22);
		}
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-secondary unit(28, 29));

		.Theme--red &,
		.ThemeBlock--red & {
			background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-primary unit(28, 29));
		}
	}
}
