body {
	background-color: $color-white;
	color: $color-primary;
	font-family: $font-primary;
	font-size: 112.5%;
	font-weight: normal;
	line-height: ms(1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include bp(medium) {
		font-size: 125%;
	}
	@include bp(larger) {
		font-size: 137.5%;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

p {
	margin: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	color: inherit;
	outline: 0;
	text-decoration: none;
}

b,
strong {
	font-family: $font-extended;
	font-weight: 700;
}
