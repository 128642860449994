.TrackList {
	margin: 0 ms(0.5em);
}

.TrackList-group {

	& + & {
		margin-top: ms(1em);
		@include bp(small) {
			margin-top: ms(2em);
		}
		@include bp(large) {
			margin-top: ms(3em);
		}
	}
}

.TrackList-group-title {
	font-size: 1.3em;
	font-weight: 500;
	line-height: 1.3;
}

.TrackList-group-labels {
	padding: 0.3em 0;

	* + & {
		margin-top: ms(0.5em);
	}
}

.TrackList-label {
	display: block;
	color: $color-light-gray;
	font-size: em(15, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}
}

.TrackList-label--samples {
	float: left;
	//text-align: right;
	width: em(68, 15);
	@include bp(medium) {
		width: em(72, 16);
	}
	@include bp(larger) {
		width: em(78, 16);
	}
}

.TrackList-label--songbooks {

	.TrackList--numbered & {
		margin-left: em(32, 15);
		@include bp(medium) {
			margin-left: em(36, 16);
		}
		@include bp(larger) {
			margin-left: em(42, 16);
		}
	}

	.TrackList--playable & {
		margin-left: em(48, 15);
		@include bp(medium) {
			margin-left: em(48, 16);
		}
		@include bp(larger) {
			margin-left: em(54, 16);
		}
	}

	.TrackList--numbered.TrackList--playable & {
		margin-left: em(80, 15);
		@include bp(medium) {
			margin-left: em(84, 16);
		}
		@include bp(larger) {
			margin-left: em(96, 16);
		}
	}
}

.TrackList-label-icon {
	position: relative;
	top: 0.125em;
	margin-right: 0.25em;
}

.TrackList-label-icon--bonus {
	color: $color-secondary;
}

.TrackList-items {
	padding: 0.3em 0;
}

.TrackList-item {

}

.TrackList-footer {
	margin-top: ms(1em);

	.TrackList--numbered & {
		margin-left: em(32, 18);
		@include bp(medium) {
			margin-left: em(36, 20);
		}
		@include bp(larger) {
			margin-left: em(42, 22);
		}
	}

	.TrackList--playable & {
		margin-left: em(48, 18);
		@include bp(medium) {
			margin-left: em(48, 20);
		}
		@include bp(larger) {
			margin-left: em(54, 22);
		}
	}

	.TrackList--numbered.TrackList--playable & {
		margin-left: em(80, 18);
		@include bp(medium) {
			margin-left: em(84, 20);
		}
		@include bp(larger) {
			margin-left: em(96, 22);
		}
	}
}
