.GallerySlide {
	position: relative;
	width: 100%;
	height: 100%;
}

.GallerySlide-asset {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		display: block;
		object-fit: contain;
	}
}

.GallerySlide-body {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -3.75em;
	margin: 0;
	padding: 0.75em;
	text-align: center;
	background-color: $color-primary;

	@include bp(smaller) {
		padding: 1.5em;
	}
}
