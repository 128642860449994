.ResponsiveMedia {
	position: relative;
	padding-bottom: unit(9, 16);
	overflow: hidden;
	display: block;
}

.ResponsiveMedia iframe,
.ResponsiveMedia img,
.ResponsiveMedia video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	background-color: transparent;
}

.ResponsiveMedia--cover {
    bottom: 0;
    left: 0;
    padding-bottom: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
}

.ResponsiveMedia-poster,
.ResponsiveMedia-poster-image,
.ResponsiveMedia-poster-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.ResponsiveMedia-poster-image {
	width: 100%;
	height: 100%;
	display: block;
}

.ResponsiveMedia-poster-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.ResponsiveMedia--fit--contain img,
.ResponsiveMedia--fit--contain video {
    object-fit: contain;
    font-family: "object-fit: contain;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--top-left img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--top-left video {
    object-position: left top;
    font-family: "object-fit: contain; object-position: left top;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--top-center img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--top-center video {
    object-position: center top;
    font-family: "object-fit: contain; object-position: center top;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--top-right img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--top-right video {
    object-position: right top;
    font-family: "object-fit: contain; object-position: right top;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--center-left img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--center-left video {
    object-position: left center;
    font-family: "object-fit: contain; object-position: left center;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--center-center img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--center-center video {
    object-position: center center;
    font-family: "object-fit: contain; object-position: center center;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--center-right img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--center-right video {
    object-position: right center;
    font-family: "object-fit: contain; object-position: right center;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-left img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-left video {
    object-position: left bottom;
    font-family: "object-fit: contain; object-position: left bottom;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-center img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-center video {
    object-position: center center;
    font-family: "object-fit: contain; object-position: center bottom;";
}

.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-right img,
.ResponsiveMedia--fit--contain.ResponsiveMedia--bottom-right video {
    object-position: right bottom;
    font-family: "object-fit: contain; object-position: right bottom;";
}

.ResponsiveMedia--fit--cover img,
.ResponsiveMedia--fit--cover video {
    object-fit: cover;
    font-family: "object-fit: cover;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--top-left img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--top-left video {
    object-position: left top;
    font-family: "object-fit: cover; object-position: left top;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--top-center img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--top-center video {
    object-position: center top;
    font-family: "object-fit: cover; object-position: center top;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--top-right img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--top-right video {
    object-position: right top;
    font-family: "object-fit: cover; object-position: right top;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--center-left img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--center-left video {
    object-position: left center;
    font-family: "object-fit: cover; object-position: left center;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--center-center img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--center-center video {
    object-position: center center;
    font-family: "object-fit: cover; object-position: center center;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--center-right img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--center-right video {
    object-position: right center;
    font-family: "object-fit: cover; object-position: right center;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-left img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-left video {
    object-position: left bottom;
    font-family: "object-fit: cover; object-position: left bottom;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-center img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-center video {
    object-position: center center;
    font-family: "object-fit: cover; object-position: center bottom;";
}

.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-right img,
.ResponsiveMedia--fit--cover.ResponsiveMedia--bottom-right video {
    object-position: right bottom;
    font-family: "object-fit: cover; object-position: right bottom;";
}

.ResponsiveMedia--fit--scale-down img,
.ResponsiveMedia--fit--scale-down video {
    object-fit: scale-down;
    font-family: "object-fit: scale-down;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-left img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-left video {
    object-position: left top;
    font-family: "object-fit: scale-down; object-position: left top;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-center img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-center video {
    object-position: center top;
    font-family: "object-fit: scale-down; object-position: center top;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-right img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--top-right video {
    object-position: right top;
    font-family: "object-fit: scale-down; object-position: right top;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-left img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-left video {
    object-position: left center;
    font-family: "object-fit: scale-down; object-position: left center;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-center img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-center video {
    object-position: center center;
    font-family: "object-fit: scale-down; object-position: center center;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-right img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--center-right video {
    object-position: right center;
    font-family: "object-fit: scale-down; object-position: right center;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-left img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-left video {
    object-position: left bottom;
    font-family: "object-fit: scale-down; object-position: left bottom;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-center img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-center video {
    object-position: center center;
    font-family: "object-fit: scale-down; object-position: center bottom;";
}

.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-right img,
.ResponsiveMedia--fit--scale-down.ResponsiveMedia--bottom-right video {
    object-position: right bottom;
    font-family: "object-fit: scale-down; object-position: right bottom;";
}

.ResponsiveMedia--placeholder {
	background-color: $color-light-gray;
}

.ResponsiveMedia--video {
	background-color: $color-primary;
	color: $color-white;
}
