.Menu {
	@include bp(null, medium) {
		padding-top: em(88, 18);
	}
	@include bp(medium, large) {
		padding-top: em(136, 20);
	}
	@include bp(large) {
		position: relative;
		text-align: center;
	}
}

.Menu-items {
	display: inline-block;
	vertical-align: top;
	@include bp(null, large) {
		display: block;
	}
	@include bp(large) {
		@include clearfix;
	}
}

.Menu-item {
	display: block;
	@include bp(null, large) {

	}
	@include bp(large) {
		float: left;
		margin-right: ms(1em);
	}

	&:last-child {
		@include bp(large) {
			margin-right: 0;
		}
	}
}

.Menu-nav .Menu-item--newsletter {
	@include bp(large, larger) {
		display: none;
	}
	@include bp(larger) {
		margin-right: 0;
		position: absolute;
		top: 0;
		right: ms(2.175em);
	}

	.Menu-button {
		@include bp(large) {
			color: $color-medium-gray;
		}

		&:hover,
		&:active {
			@include bp(large) {
				color: $color-secondary;
			}
		}

		.Theme--black &,
		.ThemeBlock--black & {
			&:hover,
			&:active {
				@include bp(large) {
					//color: $color-white;
				}
			}
		}

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			@include bp(large) {
				color: $color-primary;
			}

			&:hover,
			&:active {
				@include bp(large) {
					color: $color-white;
				}
			}
		}
	}

	.Menu-button-label {
		@include bp(large) {
			font-size: em(14, 20);
			font-weight: 400;
			line-height: 1.25;
		}
		@include bp(larger) {
			font-size: em(14, 22);
		}
	}
}

.Menu-button {
	display: block;
	padding: em(9.25, 18) 0;
	line-height: 1.25;
	transition-duration: 200ms;
	transition-property: color;
	transition-timing-function: ease-out;
	@include bp(null, large) {
		line-height: 1;
	}
	@include bp(null, smaller) {
		padding-top: em(5.25, 18);
		padding-bottom: em(5.25, 18);
	}
	@include bp(null, small) {
		padding-left: em(30, 18);
		padding-right: em(30, 18);
	}
	@include bp(small, medium) {
		padding-left: ms(1em);
		padding-right: ms(1em);
	}
	@include bp(medium) {
		padding-top: em(17.5, 20);
		padding-bottom: em(17.5, 20);
	}
	@include bp(medium, large) {
		padding-left: 25%;
		padding-right: 25%;
	}
	@include bp(larger) {
		padding-top: em(16.25, 22);
		padding-bottom: em(16.25, 22);
	}

	&:hover,
	&:active {
		color: $color-secondary;
	}

	.Theme--red &,
	.ThemeBlock--red &,
	.ColorBlock--secondary &,
	.Color--secondary & {

		&:hover,
		&:active {
			color: $color-primary;
		}
	}
}

.Menu-button-label {
	position: relative;
	top: em(-2, 29.5);
	font-size: em(29.5, 18);
	font-weight: 500;
	padding-bottom: em(4, 29.5);
	@include bp(smaller) {
		padding-bottom: em(4, 29.5);
	}
	@include bp(medium) {
		top: em(-2, 33);
		font-size: em(33, 20);
		padding-bottom: em(4.5, 33);
	}
	@include bp(large) {
		top: em(-2, 16.5);
		font-size: em(16.5, 20);
		padding-bottom: em(4.5, 16.5);
	}
	@include bp(larger) {
		top: em(-3, 18);
		font-size: em(18, 22);
		padding-bottom: em(4, 18);
	}

	.isActive & {
		background-image: linear-gradient(to bottom, transparent unit(20, 22), $color-secondary unit(20, 22));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(22.5, 30.5), $color-secondary unit(22.5, 24.5));
		}
		@include bp(large) {
			background-image: linear-gradient(to bottom, transparent unit(22.5, 24.5), currentColor unit(22.5, 24.5));
		}
		@include bp(larger) {
			background-image: linear-gradient(to bottom, transparent unit(24, 26), currentColor unit(24, 26));
		}

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			background-image: linear-gradient(to bottom, transparent unit(20, 22), $color-primary unit(20, 22));
			@include bp(medium) {
				background-image: linear-gradient(to bottom, transparent unit(22.5, 24.5), $color-primary unit(22.5, 24.5));
			}
			@include bp(large) {
				background-image: linear-gradient(to bottom, transparent unit(22.5, 24.5), currentColor unit(22.5, 24.5));
			}
			@include bp(larger) {
				background-image: linear-gradient(to bottom, transparent unit(24, 26), currentColor unit(24, 26));
			}
		}
	}
}

.Menu-nav,
.Menu-social,
.Menu-locales {
	@include bp(null, smaller) {
		padding-top: em(5.25, 18);
	}
	@include bp(smaller, small) {
		margin-left: ms(-0.25em);
		margin-right: ms(-0.25em);
	}
	@include bp(smaller, medium) {
		padding-top: em(9.25, 18);
	}
	@include bp(medium, large) {
		padding-top: em(17.5, 20);
	}

	&:last-child {
		@include bp(null, smaller) {
			padding-bottom: em(5.25, 18);
		}
		@include bp(smaller, medium) {
			padding-bottom: em(9.25, 18);
		}
		@include bp(medium, large) {
			padding-bottom: em(17.5, 20);
		}
	}
}

.Menu-social {
	@include bp(null, small) {
		padding-left: em(30, 18);
		padding-right: em(30, 18);
	}
	@include bp(small, medium) {
		padding-left: ms(1em);
		padding-right: ms(1em);
	}
	@include bp(medium, large) {
		padding-left: 25%;
		padding-right: 25%;
	}
	@include bp(large) {
		display: none;
		position: absolute;
		top: em(6, 20);
		right: em(12, 20);
	}
	@include bp(larger) {
		top: em(6, 22);
		right: em(12, 22);
	}

	.Menu-items {
		@include clearfix;
		@include bp(null, medium) {
			margin-left: em(-18, 18);
		}
		@include bp(medium, large) {
			margin-left: em(-18, 20);
		}

		.Menu-item {
			float: left;
			margin-right: em(6, 18);
			@include bp(medium) {
				margin-right: em(6, 20);
			}
			@include bp(large) {
				margin-left: 0;
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.Menu-locales {
	@include bp(null, small) {
		padding-left: em(30, 18);
		padding-right: em(30, 18);
	}
	@include bp(small, medium) {
		padding-left: ms(1em);
		padding-right: ms(1em);
	}
	@include bp(medium, large) {
		padding-left: 25%;
		padding-right: 25%;
	}
	@include bp(large) {
		position: absolute;
		top: em(6, 20);
		right: em(12, 20);
	}
	@include bp(larger) {
		top: em(6, 22);
		right: em(12, 22);
	}

	.Menu-items {
		@include clearfix;
		@include bp(null, medium) {
			margin-left: em(-12, 18);
		}
		@include bp(medium, large) {
			margin-left: em(-12, 20);
		}

		.Menu-item {
			float: left;
			margin-right: em(6, 18);
			@include bp(medium) {
				margin-right: em(6, 20);
			}
			@include bp(large) {
				margin-left: 0;
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
