.Lead {

}

.Lead-header {
	@include bp(small) {
		margin: 0 4.16666%;
	}
}

.Lead-body {
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		margin-left: 16.66666%;
		margin-right: 16.66666%;
	}
	@include bp(larger) {
		margin-left: 20%;
		margin-right: 20%;
	}
	@include bp(huge) {
		margin-left: 25%;
		margin-right: 25%;
	}
	@include bp(ultra) {
		margin-left: 28.57142%;
		margin-right: 28.57142%;
	}

	* + & {
		margin-top: ms(1.5em);
		@include bp(medium) {
			margin-top: ms(2em);
		}

		.Lead--large & {
			margin-top: ms(1.5em);
			@include bp(small) {
				margin-top: ms(2em);
			}
			@include bp(large) {
				margin-top: ms(3em);
			}
		}
	}

	.Lead--small & {
		text-align: center;
		@include bp(small) {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
		}
		@include bp(medium) {
			margin-left: 20%;
			margin-right: 20%;
		}
		@include bp(large) {
			margin-left: 25%;
			margin-right: 25%;
		}
		@include bp(larger) {
			margin-left: 30%;
			margin-right: 30%;
		}
		@include bp(huge) {
			margin-left: 33.33333%;
			margin-right: 33.33333%;
		}
		@include bp(ultra) {
			margin-left: 35.71428%;
			margin-right: 35.71428%;
		}
	}
}

.Lead-heading,
.Lead-excerpt,
.Lead-cta {
	padding: 0 ms(0.5em);
}

.Lead-heading {
	text-align: center;

	.Lead--empty & {
		color: $color-secondary;

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			color: $color-primary;
		}
	}
}

.Lead-excerpt {

	* + & {
		margin-top: ms(0.75em);
		@include bp(medium) {
			margin-top: ms(1em);
		}
	}
}

.Lead-cta {
	text-align: center;

	* + & {
		margin-top: ms(1em);
		@include bp(medium) {
			margin-top: ms(1.25em);
		}
	}
}

.Lead--feature {
	padding: ms(2em) 0;
	@include bp(larger) {
		padding-top: ms(3em);
		padding-bottom: ms(3em);
	}
}

.Lead--centered,
.Lead--404 {
	text-align: center;
}

.Lead--404 {

	.Lead-header + .Lead-body {
		margin-top: ms(0.75em);
	}
}
