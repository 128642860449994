.Meta {
	font-size: em(15, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(17, 18), currentColor unit(17, 18));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(18, 19), currentColor unit(18, 19));
		}
	}

	time {
		padding-right: 0.5em;
	}
}

.Meta--small {
	font-size: em(12, 18);
	@include bp(medium) {
		font-size: em(14, 20);
	}
	@include bp(larger) {
		font-size: em(14, 22);
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(13, 14), currentColor unit(13, 14));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(16, 17), currentColor unit(16, 17));
		}
	}
}

.Meta--article {

	time {
		margin-top: ms(0.75em, 15 / 18);
		display: block;
		padding-right: 0;
		font-family: $font-extended;
		font-weight: 700;
		@include bp(medium) {
			margin-top: ms(1em, 16 / 20);
		}
		@include bp(larger) {
			margin-top: ms(1em, 16 / 22);
		}
	}
}

.Meta--articleTeaser {

	a {
		background-image: none;
	}
}
