.PaginatedList {
	padding-top: ms(0.5em);
	@include bp(smaller) {
		padding-top: ms(1em);
	}
	@include bp(medium) {
		padding-top: ms(1.5em);
	}
}

.PaginatedList-top,
.PaginatedList-bottom {
	margin: 0 ms(0.5em);
	text-align: center;
}

.PaginatedList-top {
	margin-bottom: ms(1em);
	@include bp(smaller) {
		margin-bottom: ms(1.5em);
	}
	@include bp(medium) {
		margin-bottom: ms(2em);
	}

	.PaginatedList.topIsLoaded & {
		display: none;
	}
}

.PaginatedList-bottom {
	padding-bottom: ms(0.5em);
	@include bp(smaller) {
		padding-bottom: ms(1em);
	}
	@include bp(medium) {
		padding-bottom: ms(1.5em);
	}

	* + & {
		margin-top: ms(1em);
		@include bp(smaller) {
			margin-top: ms(1.5em);
		}
		@include bp(medium) {
			margin-top: ms(2em);
		}
	}

	.Grid + & {
		margin-top: 0;
	}

	.PaginatedList.bottomIsLoaded & {
		display: none;
	}
}
