.Hero {

}

.Hero-header,
.Hero-body,
.Hero-footer {

	* + & {
		margin-top: ms(0.5em);
		@include bp(medium) {
			margin-top: ms(0.75em);
		}
	}
}

.Hero-visual {
	position: relative;
	padding: 0 ms(0.5em);
	display: block;

	& + *,
	* + & {
		margin-top: ms(1em);
		@include bp(medium) {
			margin-top: ms(1.5em);
		}
	}
}

.Hero-visual-credit {
	position: absolute;
	right: 0;
	bottom: ms(-1em);
	margin-right: ms(0.5em);
}

.Hero-heading,
.Hero-subheading,
.Hero-meta,
.Hero-excerpt,
.Hero-cta {
	padding: 0 ms(0.5em);

	* + & {
		margin-top: ms(0.25em);
	}
}

.Hero--article {

	.Hero-header {
		text-align: center;
		@include bp(small) {
			margin-left: 8.33333%;
			margin-right: 8.33333%;
		}
		@include bp(medium) {
			margin-left: 10%;
			margin-right: 10%;
		}
		@include bp(large) {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
		}
		@include bp(larger) {
			margin-left: 20%;
			margin-right: 20%;
		}
		@include bp(huge) {
			margin-left: 25%;
			margin-right: 25%;
		}
		@include bp(ultra) {
			margin-left: 28.57142%;
			margin-right: 28.57142%;
		}
	}

	* + .Hero-header {
		margin-top: ms(1.5em);
		@include bp(small) {
			margin-top: ms(2em);
		}
		@include bp(large) {
			margin-top: ms(4em);
		}
	}

	&.Hero--stack .Hero-header {
		position: relative;
	}

	.Hero-visual + *,
	* + .Hero-visual {
		margin-top: ms(1.5em);
		@include bp(medium) {
			margin-top: ms(2em);
		}
	}

	.Hero-body {
		text-align: center;
		@include bp(small) {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
		}
		@include bp(medium) {
			margin-left: 20%;
			margin-right: 20%;
		}
		@include bp(large) {
			margin-left: 25%;
			margin-right: 25%;
		}
		@include bp(larger) {
			margin-left: 30%;
			margin-right: 30%;
		}
		@include bp(huge) {
			margin-left: 33.33333%;
			margin-right: 33.33333%;
		}
		@include bp(ultra) {
			margin-left: 35.71428%;
			margin-right: 35.71428%;
		}
	}

	* + .Hero-subline,
	* + .Hero-meta,
	* + .Hero-excerpt {
		margin-top: ms(0.75em);
		@include bp(medium) {
			margin-top: ms(1em);
		}
	}

	* + .Hero-cta {
		margin-top: ms(1em);
		@include bp(medium) {
			margin-top: ms(1.25em);
		}
	}
}

.Hero--portrait {

	.Hero-visual {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
		@include bp(small) {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
		}
		@include bp(medium) {
			margin-left: 20%;
			margin-right: 20%;
		}
		@include bp(large) {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
		}
		@include bp(larger) {
			margin-left: 20%;
			margin-right: 20%;
		}
		@include bp(huge) {
			margin-left: 25%;
			margin-right: 25%;
		}
		@include bp(ultra) {
			margin-left: 28.57142%;
			margin-right: 28.57142%;
		}

		&.Hero-visual--portrait {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
			@include bp(small) {
				margin-left: 25%;
				margin-right: 25%;
			}
			@include bp(medium) {
				margin-left: 25%;
				margin-right: 25%;
			}
			@include bp(large) {
				margin-left: 25%;
				margin-right: 25%;
			}
			@include bp(larger) {
				margin-left: 30%;
				margin-right: 30%;
			}
			@include bp(huge) {
				margin-left: 33.33333%;
				margin-right: 33.33333%;
			}
			@include bp(ultra) {
				margin-left: 35.71428%;
				margin-right: 35.71428%;
			}
		}
	}
}

.Hero--panorama {

	.Hero-visual {
		margin-left: 0;
		margin-right: 0;
		@include bp(null, ultra) {
			padding-left: 0;
			padding-right: 0;
		}
		@include bp(smaller, small) {
			margin-left: ms(-0.25em);
			margin-right: ms(-0.25em);
		}
		@include bp(small, medium) {
			margin-left: ms(-0.5em);
			margin-right: ms(-0.5em);
		}
		@include bp(medium, larger) {
			margin-left: ms(-1em);
			margin-right: ms(-1em);
		}
		@include bp(larger, ultra) {
			margin-left: ms(-1.5em);
			margin-right: ms(-1.5em);
		}
	}

	.Hero-visual-credit {
		@include bp(smaller, small) {
			margin-right: ms(0.75em);
		}
		@include bp(small, medium) {
			margin-right: ms(1em);
		}
		@include bp(medium, larger) {
			margin-right: ms(1.5em);
		}
		@include bp(larger, ultra) {
			margin-right: ms(2em);
		}
	}
}

.Hero--feature,
.Hero--interview {

	.Hero-header {
		margin-left: 0;
		margin-right: 0;
		text-align: left;

		& + .Hero-visual {
			margin-top: -1em;
			@include bp(small) {
				margin-top: -3.636vw;
			}
			@include bp(ultra) {
				margin-top: em(-61, 22);
			}
		}
	}
}

.Hero--feature {

	.Hero-header {
		margin-left: 4.16666%;
		margin-right: 4.16666%;
		text-align: center;
	}

	.Hero-header + .Hero-visual {
		margin-top: em(-10, 18);
		@include bp(small) {
			margin-top: -1.964285714vw;
		}
		@include bp(ultra) {
			margin-top: em(-33, 22);
		}
	}

	* + .Hero-body {
		margin-top: ms(1.5em);
		@include bp(medium) {
			margin-top: ms(2em);
		}
	}

	.Hero-body {
		@include bp(large) {
			margin-left: 8.33333%;
			margin-right: 8.33333%;
		}
	}

	&.Hero--large {

		.Hero-header {
			margin-left: 0;
			margin-right: 0;
			text-align: left;
		}

		.Hero-header + .Hero-visual {
			margin-top: -1em;
			@include bp(small) {
				margin-top: -3.636vw;
			}
			@include bp(ultra) {
				margin-top: em(-61, 22);
			}
		}
	}

	&.Hero--portrait {

		.Hero-visual {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
			@include bp(small) {
				margin-left: 25%;
				margin-right: 25%;
			}
			@include bp(medium) {
				margin-left: 30%;
				margin-right: 30%;
			}
			@include bp(large) {
				margin-left: 33.33333%;
				margin-right: 33.33333%;
			}
			@include bp(larger) {
				margin-left: 35%;
				margin-right: 35%;
			}
			@include bp(huge) {
				margin-left: 37.5%;
				margin-right: 37.5%;
			}
			@include bp(ultra) {
				margin-left: 39.28571%;
				margin-right: 39.28571%;
			}
		}

		&.Hero--large {

			.Hero-visual {
				margin-left: 8.33333%;
				margin-right: 8.33333%;
				@include bp(small) {
					margin-left: 16.66666%;
					margin-right: 16.66666%;
				}
				@include bp(medium) {
					margin-left: 20%;
					margin-right: 20%;
				}
				@include bp(large) {
					margin-left: 16.66666%;
					margin-right: 16.66666%;
				}
				@include bp(larger) {
					margin-left: 20%;
					margin-right: 20%;
				}
				@include bp(huge) {
					margin-left: 25%;
					margin-right: 25%;
				}
				@include bp(ultra) {
					margin-left: 28.57142%;
					margin-right: 28.57142%;
				}
			}
		}
	}
}

.Hero--interview {

	.Hero-heading {
		color: $color-secondary;
		padding-left: ms(0.25em);
		@include bp(smaller) {
			padding-left: ms(0.375em);
		}
		@include bp(small) {
			padding-left: 0;
		}
		@include bp(medium) {
			margin-left: ms(-0.25em);
		}
		@include bp(larger) {
			margin-left: ms(-0.5em);
		}
	}
}

.Hero--recommendation {

	.Hero-header {
		text-align: center;
		margin-left: 0;
		margin-right: 0;

		& + .Hero-visual {
			@include bp(small) {
				margin-top: -1.964285714vw;
			}
			@include bp(ultra) {
				margin-top: em(-33, 22);
			}
		}
	}
}

.Hero--stack {
	position: relative;

	.Hero-header {
		position: relative;
		z-index: 1;
		@include bp(medium) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		& + .Hero-visual {
			margin-top: 0;
		}
	}

	.Hero-visual {
		position: relative;
		z-index: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.Hero--tour {
	@include bp(medium) {
		@include clearfix;
	}

	.Hero-visual {
		padding-bottom: 100%;
		@include bp(medium) {
			padding-bottom: 75%;
		}
		@include bp(large) {
			padding-bottom: 66.66666%;
		}
		@include bp(larger) {
			padding-bottom: unit(9, 16);
		}
	}

	.Hero-header {
		margin-top: 0;
		padding-top: ms(0.5em);
		@include bp(medium) {
			padding-top: ms(1.5em);
			float: left;
			width: 50%;
		}
		@include bp(huge) {
			width: 41.66666%;
		}
	}

	.Hero-body {
		@include bp(medium) {
			margin-top: ms(1.75em);
			padding-top: em(24, 20);
			float: left;
			width: 50%;
		}
		@include bp(larger) {
			margin-top: ms(1.75em);
			padding-top: em(24, 22);
		}
		@include bp(huge) {
			margin-left: 8.33333%;
			width: 41.66666%;
		}
	}

	.Hero-footer {
		margin-top: ms(1em);
		@include bp(medium) {
			clear: both;
			margin-top: 0;
			padding-top: ms(1.5em);
		}
	}

	.Hero-cta {
		text-align: center;
	}
}
