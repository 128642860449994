.Sequence {

}

.Sequence--media {

	.Sequence-next,
	.Sequence-prev {
		position: relative;

		* + & {
			@include bp(null, medium) {
				margin-top: 0.75em;
			}
		}
	}

	.Sequence-next-button,
	.Sequence-prev-button {
		position: relative;
		z-index: 1;
	}

	.Sequence-next-teaser,
	.Sequence-prev-teaser {
		position: relative;
		z-index: 0;
	}

	.Sequence-next {
		@include bp(medium) {
			margin-left: 25%;
		}
		@include bp(large) {
			margin-left: 33.33333%;
		}
		@include bp(huge) {
			margin-left: 33.33333%;
		}
	}

	.Sequence-next-button {
		position: absolute;
		top: 0;
		right: 66.66666%;
		margin-top: unit(1, 8);
		margin-right: -1.5em;
		@include bp(medium) {
			margin-top: 4.16666%;
			margin-right: -4em;
		}
		@include bp(large) {
			right: 75%;
			margin-top: 8.33333%;
			margin-right: -4.75em;
		}
		@include bp(huge) {
			right: unit(6, 8);
		}
	}

	.Sequence-next-teaser {
		margin-left: 25%;
		@include bp(medium) {
			margin-left: 33.33333%;
		}
		@include bp(large) {
			margin-left: 25%;
		}
		@include bp(huge) {
			margin-left: 25%;
			margin-right: 25%;
		}
	}

	.Sequence-prev {
		@include bp(medium) {
			margin-top: -6em;
			margin-right: 50%;
		}
		@include bp(large) {
			margin-right: unit(4, 6);
			margin-top: -11em;
		}
		@include bp(huge) {
			margin-right: 66.66666%;
		}
	}

	.Sequence-prev-teaser {
		margin-top: 0.75em;
	}
}

.Sequence--song {
	@include clearfix;

	.Sequence-next,
	.Sequence-prev {
		width: 50%;
		@include bp(null, medium) {
			margin-bottom: ms(1em);
		}
		@include bp(medium) {
			margin-top: 8.33333%;
			width: 33.33333%;
		}
		@include bp(huge) {
			margin-top: unit(1, 18);
			width: unit(1.5, 4);
		}
	}

	.Sequence-next {
		float: right;
		text-align: right;
	}

	.Sequence-prev {
		float: left;
	}

	.Sequence-body {
		margin: 0 25%;
		@include bp(null, medium) {
			clear: both;
		}
		@include bp(medium) {
			margin-left: 33.33333%;
			margin-right: 33.33333%;
		}
		@include bp(huge) {
			margin-left: unit(1.5, 4);
			margin-right: unit(1.5, 4);
		}
	}
}
