.Section {
	padding: ms(2em) 0 ms(4em);
	@include bp(larger) {
		padding-top: ms(3em);
		padding-bottom: ms(6em);
	}

	.Page-toolbar + &,
	.Page-header + &.ThemeBlock--default,
	.ThemeBlock--default + &.ThemeBlock--default,
	.ThemeBlock--black + &.ThemeBlock--black,
	.ThemeBlock--darkGray + &.ThemeBlock--darkGray,
	.ThemeBlock--gray + &.ThemeBlock--gray,
	.ThemeBlock--white + &.ThemeBlock--white,
	.ThemeBlock--red + &.ThemeBlock--red,
	.ThemeBlock--gold + &.ThemeBlock--gold {
		padding-top: 0;
	}

	&.Section--hasVisual {
		position: relative;
		z-index: 0;

		.ThemeBlock--default + &,
		.ThemeBlock--black + &,
		.ThemeBlock--darkGray + &,
		.ThemeBlock--gray + &,
		.ThemeBlock--white + &,
		.ThemeBlock--red + &,
		.ThemeBlock--gold + & {
			padding-top: ms(2em);
			@include bp(larger) {
				padding-top: ms(3em);
			}
		}
	}
}

.Section-visual {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.Section-header {
	margin: 0 ms(0.5em) ms(2.25em) ms(0.5em);
	text-align: center;
	@include bp(larger) {
		margin-bottom: ms(3.25em);
	}
}

.Section-feature {
	padding-bottom: ms(2.5em);
	@include bp(medium) {
		padding-bottom: ms(2em);
	}
	@include bp(large) {
		padding-bottom: ms(4em);
	}
	@include bp(larger) {
		padding-bottom: ms(5em);
	}
}

.Section-footer {
	margin: 0 ms(0.5em);
	text-align: center;

	* + & {
		margin-top: ms(2.25em);
		@include bp(larger) {
			margin-top: ms(3.25em);
		}
	}

	.Grid + & {
		margin-top: 0;
	}

	.Grid--releases + & {
		margin-top: ms(2.25em);
		@include bp(larger) {
			margin-top: ms(3.25em);
		}
	}

	.GridScroller--releases + & {
		margin-top: ms(1em);
		@include bp(large) {
			margin-top: ms(1.5em);
		}
	}
}

.Section--chronicleIndex {
	padding-bottom: 0;

	.Section-header {
		margin-bottom: 0;
	}
}

.Section--bleed,
.Section--full,
.Section--fullTop {
	padding-top: 0;
}

.Section--bleed,
.Section--full,
.Section--fullBottom {
	padding-bottom: 0;
}

.Section--bleed {

	.Section-header {
		padding-top: ms(2em);
		padding-bottom: ms(2.25em);
		@include bp(larger) {
			padding-top: ms(3em);
			padding-bottom: ms(3.25em);
		}
	}

	.Section-footer {
		padding-top: ms(2.25em);
		padding-bottom: ms(4em);
		@include bp(larger) {
			padding-top: ms(3.25em);
			padding-bottom: ms(6em);
		}
	}
}

.Section--full,
.Section--fullTop {

	.Section-header {
		position: relative;
		margin-bottom: 0;
		z-index: 1;
	}

	.Section-label {
		position: absolute;
		top: ms(2em);
		left: 0;
		right: 0;
		@include bp(larger) {
			top: ms(3em);
		}
	}
}

.Section--full,
.Section--fullBottom {

	.Section-footer {
		position: relative;
		z-index: 1;
		margin-top: 0;
	}

	.Section-cta {
		position: absolute;
		left: 0;
		right: 0;
		bottom: ms(2em);
		@include bp(larger) {
			bottom: ms(3em);
		}
	}
}

.Section--featuredEvents {
	padding-bottom: ms(2em);
	@include bp(larger) {
		padding-bottom: ms(3em);
	}
}

.Section--stack {
	@include bp(medium) {
		position: relative;
	}

	.Section-header {
		@include bp(medium) {
			position: relative;
			z-index: 1;
			margin-bottom: 0;
		}
	}

	.Section-label {
		@include bp(medium) {
			position: absolute;
			top: ms(1.5em);
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.Section-footer {
		@include bp(medium) {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
			max-width: 85em;
		}
	}

	.Section-cta {
		@include bp(medium) {
			position: absolute;
			z-index: 1;
			top: 0;
			right: ms(1.5em);
			transform: translateY(-50%);
			text-align: right;
		}
		@include bp(larger) {
			right: ms(2em);
		}
	}
}

.Section--videoPlayer {
	padding-bottom: 0;

	.Section-footer {
		padding-bottom: ms(2em);
		@include bp(larger) {
			padding-bottom: ms(3em);
		}
	}
}
