.Chronicle {
	padding: ms(0.5em) 0;
	@include bp(smaller) {
		padding-top: ms(1em);
		padding-bottom: ms(1em);
	}
	@include bp(medium) {
		padding-top: ms(1.5em);
		padding-bottom: ms(1.5em);
	}
}

.Chronicle-inner {
	position: relative;
}

.Chronicle-header {
	position: relative;
	z-index: 1;
	text-align: center;
}

.Chronicle-visual {
	position: relative;
	z-index: 0;
	@include bp(smaller) {
		margin-left: ms(-0.25em);
		margin-right: ms(-0.25em);
	}
	@include bp(small) {
		margin-left: ms(-0.5em);
		margin-right: ms(-0.5em);
	}
	@include bp(medium) {
		margin-left: ms(-1em);
		margin-right: ms(-1em);
	}
	@include bp(larger) {
		margin-left: ms(-1.5em);
		margin-right: ms(-1.5em);
	}
}

.Chronicle-body {
	position: relative;
	z-index: 1;

	* + & {
		margin-top: ms(2em);
		@include bp(small) {
			margin-top: ms(2em);
		}
		@include bp(large) {
			margin-top: ms(3em);
		}
	}
}

.Chronicle-tie {
	position: relative;
	z-index: 0;
}

.Chronicle-media--parallax {
	position: absolute;
	top: 0;
	@include bp(medium) {
		position: absolute;
		top: 0;
	}
}

.Chronicle-heading,
.Chronicle-excerpt,
.Chronicle-cta,
.Chronicle-outline-inner {
	padding: 0 ms(0.5em);
}

.Chronicle-outline {
	position: relative;
	z-index: 1;
}

.Chronicle--index {
	padding-bottom: 0;

	.Chronicle-inner::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: ms(0.5em) ms(0.5em) 0;
		background-color: $color-off-white;
	}

	.Chronicle-body {
		margin-top: ms(0.5em);
		@include bp(small, large) {
			margin-left: 8.33333%;
			margin-right: 8.33333%;
		}
		@include bp(large) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin-top: 45%;
			margin-right: 50%;
		}
		@include bp(huge) {
			margin-left: 5%;
			margin-right: 55%;
		}
	}

	.Chronicle-heading {
		@include bp(larger) {
			margin-left: ms(-1.5em);
			margin-right: ms(-1.5em);
		}
	}

	.Chronicle-excerpt,
	.Chronicle-cta {
		@include bp(null, smaller) {
			padding-left: ms(1em);
			padding-right: ms(1em);
		}
		@include bp(smaller, small) {
			padding-left: ms(1.25em);
			padding-right: ms(1.25em);
		}
		@include bp(large) {
			padding-left: ms(1.5em);
			padding-right: ms(1.5em);
		}

		* + & {
			margin-top: ms(1em);
			@include bp(small) {
				margin-top: ms(2em);
			}
		}
	}

	.Chronicle-heading,
	.Chronicle-cta {
		text-align: center;
	}

	.Chronicle-excerpt {

		* + & {
			@include bp(medium) {
				margin-top: ms(3em);
			}
		}
	}

	.Chronicle-tie--footer {
		padding-top: ms(2em);
		padding-bottom: ms(2em);
		@include bp(large) {
			margin-top: -16.66666%;
			padding-top: 0;
		}
	}

	.Chronicle-media--18 {
		margin-top: -10.41666%;
		left: unit(-82, 1280);
		width: unit(550, 1280);
	}

	.Chronicle-media--19 {
		position: relative;
		margin: 0 auto;
		width: unit(178, 1280);
	}
}

.Chronicle--eighties,
.Chronicle--nineties,
.Chronicle--noughties,
.Chronicle--teenies,
.Chronicle--twenties {

	.Heading a {
		padding: 0.1em 0;
		background-image: linear-gradient(to bottom, transparent unit(11, 12), currentColor unit(11, 12));
	}

	.Chronicle-heading {
		color: $color-secondary;
	}
}

.Chronicle--eighties {

	.Chronicle-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		margin-top: unit(115, 1280);
	}

	.Chronicle-outline-inner {
		@include bp(medium) {
			margin-top: -8.33333%;
			margin-left: 50%;
		}
	}

	.Chronicle-body {

	}

	.Chronicle-tie--footer {
		margin-top: ms(3em);
		@include bp(small) {
			margin-top: ms(4em);
		}
		@include bp(large) {
			margin-top: ms(6em);
		}
	}

	.Chronicle-media--14 {
		right: ms(0.5em);
		width: unit(458, 1181);
		@include bp(null, medium) {
			display: none;
		}
		@include bp(medium) {
			width: unit(229, 1181);
		}
		@include bp(large) {
			//left: 83.33333%;
			//width: 16.66666%;
		}
		@include bp(larger) {
			//left: unit(8, 10);
			//width: unit(1.5, 10);
		}
		@include bp(huge) {
			//left: unit(9.5, 12);
			//width: 16.66666%;
		}
	}

	.Chronicle-media--15 {
		left: ms(0.5em);
		width: unit(332, 1181);
		@include bp(null, medium) {
			left: auto;
			right: ms(0.5em);
			margin-top: ms(-1em);
		}
		@include bp(medium) {
			margin-top: ms(-4em);
			width: unit(166, 1181);
		}
		@include bp(large) {
			margin-top: ms(-6em);
			//left: 83.33333%;
			//width: 16.66666%;
		}
		@include bp(larger) {
			//left: unit(8, 10);
			//width: unit(1.5, 10);
		}
		@include bp(huge) {
			//left: unit(9.5, 12);
			//width: 16.66666%;
		}
	}

	.Chronicle-media--16 {
		position: relative;
		z-index: 0;
	}

	.Chronicle-media--17 {
		z-index: 1;
		right: ms(0.5em);
		width: unit(627, 1181);
		@include bp(null, medium) {
			display: none;
		}
		@include bp(medium) {
			width: unit(418, 1181);
		}
	}
}

.Chronicle--nineties {

	.Chronicle-visual {
		margin-top: ms(2em);
		@include bp(small) {
			margin-top: ms(2em);
		}
		@include bp(large) {
			margin-top: ms(3em);
		}
	}

	.Chronicle-outline-inner {
		@include bp(medium) {
			margin-top: -25%;
			margin-right: 50%;
		}
	}

	.Chronicle-media--15 {
		left: unit(323, 1181);
		width: unit(289, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(323, 1181);
			width: unit(289, 1181);
		}
	}

	.Chronicle-tie--footer {
		margin-top: 33.33333%;
	}

	.Chronicle-media--16 {
		margin-top: -12.5%;
		left: unit(858, 1181);
		width: unit(304, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(858, 1181);
			width: unit(304, 1181);
		}
	}

	.Chronicle-media--17 {
		margin-top: -25%;
		left: unit(791, 1181);
		width: unit(502, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(791, 1181);
			width: unit(502, 1181);
		}
	}

	.Chronicle-media--19 {
		margin-top: 4.16666%;
		left: unit(613, 1181);
		width: unit(268, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(613, 1181);
			width: unit(268, 1181);
		}
	}
}

.Chronicle--noughties {

	.Chronicle-visual {
		margin-top: ms(2em);
		@include bp(small) {
			margin-top: ms(2em);
		}
		@include bp(large) {
			margin-top: ms(3em);
		}
	}

	.Chronicle-outline-inner {
		@include bp(medium) {
			margin-top: -41.66666%;
			margin-right: 50%;
		}
		@include bp(large) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin-top: -41.66666%;
			margin-right: 50%;
		}
	}

	.Chronicle-body {
		@include bp(large) {
			margin-top: 0;
		}
	}

	.Chronicle-media--15 {
		left: unit(-99, 1181);
		width: unit(330, 1181);
		@include bp(null, medium) {
			margin-top: -95%;
		}
		@include bp(medium) {
			margin-top: 10%;
			left: -10%;
			width: 20%;
		}
		@include bp(large) {
			left: -8.33333%;
			width: 25%;
		}
	}

	.Chronicle-tie--footer {
		margin-top: 16.66666%;
	}

	.Chronicle-media--17 {
		margin-top: -12.5%;
		left: unit(474, 1181);
		width: unit(376, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(474, 1181);
			width: unit(376, 1181);
		}
	}

	.Chronicle-media--18 {
		margin-top: -8.33333%;
		left: unit(862, 1181);
		width: unit(309, 1181);
		@include bp(null, medium) {
			//margin-top: -12.5%;
		}
		@include bp(medium) {
			left: unit(862, 1181);
			width: unit(309, 1181);
		}
	}
}

.Chronicle--teenies {

	.Chronicle-outline-inner {
		@include bp(medium) {
			margin-top: -25%;
			margin-right: 50%;
		}
	}

	.Chronicle-tie--footer {
		margin-top: 25%;
	}

	.Chronicle-media--10 {
		margin-top: -25%;
		left: unit(493, 1181);
		width: unit(574, 1181);
		@include bp(medium) {
			left: unit(493, 1181);
			width: unit(574, 1181);
		}
	}

	.Chronicle-media--12 {
		//margin-top: -8.33333%;
		left: unit(768, 1181);
		width: unit(437, 1181);
		@include bp(medium) {
			left: unit(768, 1181);
			width: unit(437, 1181);
		}
	}
}

.Chronicle--twenties {

	.Chronicle-header {
		z-index: 0;
	}

	.Chronicle-visual {
		z-index: 1;
	}

	.Chronicle-outline-inner {
		@include bp(medium) {
			margin-top: -16.66666%;
			margin-left: 50%;
			margin-right: 8.33333%;
		}
	}

	.Chronicle-tie--footer {
		margin-top: 16.66666%;
		padding-top: unit(2.25, 12);
		@include bp(medium) {
			margin-top: unit(1.3125, 12);
		}
	}

	.Chronicle-media--14 {
		background-color: $color-secondary;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: unit(-25, 1181);
		padding-bottom: unit(900, 1181);
		right: 0;
		width: unit(900, 1181);
	}

	.Chronicle-media--15 {
		@include bp(smaller) {
			margin-left: ms(-0.25em);
			margin-right: ms(-0.25em);
		}
		@include bp(small) {
			margin-left: ms(-0.5em);
			margin-right: ms(-0.5em);
		}
		@include bp(medium) {
			margin-left: ms(-1em);
			margin-right: ms(-1em);
		}
		@include bp(larger) {
			margin-left: ms(-1.5em);
			margin-right: ms(-1.5em);
		}
	}

	.Chronicle-media--16 {
		left: 0;
		margin-left: auto;
		margin-right: auto;
		right: 0;
		width: unit(388, 1181);
	}
}
