.Credit {
	font-size: em(11, 18);
	opacity: 0.3;
	@include bp(medium) {
		font-size: em(11, 20);
	}
	@include bp(larger) {
		font-size: em(11, 22);
	}

	.Caption-credit & {
		margin-top: 0.3334em;
		display: block;
	}

	.Theme--black &,
	.Theme--darkGray &,
	.Theme--red &,
	.Theme--gold &,
	.ThemeBlock--black &,
	.ThemeBlock--darkGray &,
	.ThemeBlock--red &,
	.ThemeBlock--gold &,
	.ColorBlock--primary &,
	.ColorBlock--secondary &,
	.ColorBlock--offBlack &,
	.ColorBlock--darkGray &,
	.Color--lightGray &,
	.Color--offWhite &,
	.Color--white & {
		opacity: 0.6;
	}
}
