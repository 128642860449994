.Stack {
	@include clearfix;
}

.Stack-unit {
	display: block;
	padding: ms(0.5em);
	float: left;

	&:nth-child(3n+1) {
		margin-top: 4.16666%;
		width: 75%;
	}

	&:nth-child(3n+2) {
		margin-left: -16.66666%;
		width: 41.66666%;
	}

	&:nth-child(3n+3) {
		margin-top: -33.33333%;
		margin-left: 45.83333%;
		width: 41.66666%;
	}
}

.Stack--collapsed {

	.Stack-unit {
		position: relative;
		pointer-events: none;

		&:nth-child(3n+1) {
			z-index: 1;
			margin-left: 16.66666%;
			width: 66.66666%;
		}

		&:nth-child(3n+2) {
			z-index: 0;
			margin-left: -83.33333%;
			width: 50%;
		}

		&:nth-child(3n+3) {
			z-index: 0;
			margin-top: -45.83333%;
			margin-left: 50%;
			width: 50%;
		}

		.ResponsiveMedia-body {
			pointer-events: auto;
			cursor: pointer;
		}
	}
}

.Stack--teasers {

	.Stack-unit {
		padding: 0;
		@include bp(null, medium) {
			float: none;
			width: auto;
		}

		&:nth-child(3n+1) {
			@include bp(null, medium) {
				margin-top: 0;
				margin-right: 25%;
			}
			@include bp(medium) {
				margin-top: 16.66666%;
			}
		}

		&:nth-child(3n+2) {
			@include bp(null, medium) {
				margin-top: ms(1.5em);
				margin-left: 25%;
			}
			@include bp(medium) {
				margin-left: -16.66666%;
				width: 41.66666%;
			}
		}

		&:nth-child(3n+3) {
			@include bp(null, medium) {
				margin-top: ms(1.5em);
				margin-left: 8.33333%;
				margin-right: 16.66666%;
			}
			@include bp(medium) {
				margin-top: ms(1em);
				margin-left: 41.66666%;
			}
		}
	}
}
