.Distributors {
	@include clearfix;
	position: relative;
	margin: 0 ms(0.25em);
}

.Distributors-group {
	position: relative;
	display: block;
	text-align: left;
	float: left;
	width: 50%;

	&.isExpanded {

		.Button--toggle .Icon {
			transform: rotate(180deg);
		}
	}

	.Distributors--single & {
		@include bp(large) {
			margin-left: 50%;
		}
	}
}

.Distributors-button {
	position: relative;
	z-index: 1;
	padding: 0 ms(0.25em);

	> .Button {
		display: block;
		width: 100%;
	}

	.isExpanded & {
		z-index: 3;

		> .Button--toggle .Icon {
			transform: rotate(180deg);
		}
	}
}

.Distributors-items {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	right: 0;
	//min-width: 100%;
	margin-top: em(1, 18);
	margin-left: ms(0.25em);
	margin-right: ms(0.25em);
	padding: em(15.25, 18) 0;
	background-color: $color-primary;
	//box-shadow: 0 0 ms(1em) transparentize($color-white, 0.75);
	color: $color-white;
	display: none;
	@include bp(medium) {
		margin-top: em(1, 20);
		padding-top: em(14, 20);
		padding-bottom: em(14, 20);
	}
	@include bp(larger) {
		margin-top: em(1, 22);
		padding-top: em(14, 22);
		padding-bottom: em(14, 22);
	}

	.Distributors-group--right & {
		left: auto;
		right: 0;
	}

	.Distributors-group--bottom & {
		top: auto;
		bottom: 100%;
		@include bp(medium) {
			margin-top: em(1, 20);
		}
		@include bp(larger) {
			margin-top: em(1, 22);
		}
	}

	.isSticky.isMiddle & {
		@include bp(medium) {
			top: auto;
			bottom: 100%;
			margin-top: 0;
			margin-bottom: em(1, 20);
		}
		@include bp(larger) {
			margin-bottom: em(1, 22);
		}
	}

	.isExpanded > & {
		display: block;
	}
}

.Distributors-item {
	display: block;
}

.Distributors-link {
	display: block;
	color: inherit;
	padding: em(15.25, 14) 1em * $l;
	font-size: em(14, 18);
	line-height: 1.25;
	transition-duration: 200ms;
	transition-property: background-color, color;
	transition-timing-function: ease-out;
	@include bp(medium) {
		padding: em(14, 16) em(28, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	&:hover {
		color: $color-secondary;
	}
}
