.Footer {
	padding: ms(1em) 0;
	@include bp(smaller) {
		padding-top: ms(2em);
		padding-bottom: ms(2em);
	}
	@include bp(medium) {
		padding-top: ms(3em);
		padding-bottom: ms(3em);
	}
}

.Footer-container {
	@include bp(medium) {
		position: relative;
	}
}

.Footer-locales,
.Footer-nav,
.Footer-social {
	padding: 0 ms(0.5em);
	text-align: center;

	* + &,
	* + .Footer-container &:first-child {
		margin-top: ms(1em);
	}
}
