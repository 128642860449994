.Copy {

	> * + * {
		margin-top: ms(1em);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-primary;
		font-weight: 500;
	}

	> h3 + *,
	> h4 + *,
	> h5 + *,
	> h6 + * {
		margin-top: 0;
	}

	p {
		hyphens: auto;
	}

	blockquote {
		margin: 0;
	}

	blockquote > p {
		border-left: ms(0.0625em, 1.125) solid $color-secondary;
		padding-left: ms(1em, 1.125);
		font-size: 1.125em;
		font-style: italic;
		line-height: ms(1.5, 1.125);
	}

	ol {
		list-style-type: decimal;
	}

	ul li::before {
		content: "";
		margin-top: ms(0.4em);
		margin-left: ms(-0.5em);
		background-color: currentColor;
		border-radius: 50%;
		float: left;
		width: ms(0.25em);
		height: ms(0.25em);
		@include bp(small) {
			margin-left: ms(-0.75em);
		}
	}

	li + li {
		margin-top: ms(0.5em);
	}

	dl {
		margin: 0;

		> * + dt {
			margin-top: ms(1em);
		}
	}

	dt {
		font-weight: bold;
	}

	dd {
		margin-left: 0;
	}

	a {
		background-image: linear-gradient(to bottom, transparent unit(21, 22), $color-secondary unit(21, 22));
		text-decoration: none;
		transition-duration: 200ms;
		transition-property: background-color, color;
		transition-timing-function: ease-out;
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		.Theme--red &,
		.ThemeBlock--red & {
			background-image: linear-gradient(to bottom, transparent unit(21, 22), $color-primary unit(21, 22));
		}

		&:hover,
		&:focus,
		&:active {
			outline: 0;
			color: $color-secondary;

			.Theme--red &,
			.ThemeBlock--red & {
				color: $color-primary;
			}
		}

		&:active {
			transition-duration: 0ms;
		}
	}

	em {
		//padding-bottom: 0.1em;
		font-style: normal;
		background-image: linear-gradient(to bottom, transparent unit(21, 22), currentColor unit(21, 22));
	}

	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		width: 100%;
		height: auto;
	}

	hr {
		border: none;
		display: block;
		text-align: center;
	}

	hr::after {
		display: block;
		content: "* * *";
		font-size: 1.25em;
		line-height: 1;
	}

	> hr + *,
	> * + hr {
		@include bp(small) {
			margin-top: ms(1.5em);
		}
	}
}

.Copy--large {
	font-size: 1.3em;

	a {
		background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-secondary unit(28, 29));

		.Theme--red &,
		.ThemeBlock--red & {
			background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-primary unit(28, 29));
		}
	}
}

.Copy--small {
	font-size: em(15, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	em {
		background-image: linear-gradient(to bottom, transparent unit(17, 18), currentColor unit(17, 18));
	}
}
