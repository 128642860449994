.Link {
	color: inherit;
	text-decoration: none;
	transition-duration: 200ms;
	transition-property: background-color, color;
	transition-timing-function: ease-out;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	background-image: linear-gradient(to bottom, transparent unit(20.6, 21.6), currentColor unit(20.6, 21.6));
	@include bp(medium) {
		background-image: linear-gradient(to bottom, transparent unit(22, 24), currentColor unit(22, 24));
	}
	@include bp(larger) {
		background-image: linear-gradient(to bottom, transparent unit(24.4, 26.4), currentColor unit(24.4, 26.4));
	}

	&.Link--secondary {
		color: $color-secondary;
		background-image: linear-gradient(to bottom, transparent unit(20.6, 21.6), currentColor unit(20.6, 21.6));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(22, 24), currentColor unit(22, 24));
		}
		@include bp(larger) {
			background-image: linear-gradient(to bottom, transparent unit(24.4, 26.4), currentColor unit(24.4, 26.4));
		}

		&:focus,
		&:active {
			background-color: $color-secondary;
			color: $color-white;
		}
	}

	&:focus,
	&:active {
		outline: 0;
		background-color: $color-primary;
		color: $color-white;
	}

	&:active {
		transition-duration: 0ms;
	}
}

.Link--small {
	font-size: em(14, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	background-image: linear-gradient(to bottom, transparent unit(15.8, 16.8), currentColor unit(15.8, 16.8));
	@include bp(medium) {
		background-image: linear-gradient(to bottom, transparent unit(18.2, 19.2), currentColor unit(18.2, 19.2));
	}

	&.Link--secondary {
		background-image: linear-gradient(to bottom, transparent unit(15.8, 16.8), currentColor unit(15.8, 16.8));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(18.2, 19.2), currentColor unit(18.2, 19.2));
		}
	}
}
