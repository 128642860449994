.Heading {
	display: block;
}

.Heading-headline,
.Heading-subline {
	display: block;
	font-size: 1.3em;
	line-height: 1.3;

	img {
		position: relative;
		vertical-align: top;
		top: -0.05em;
		width: auto;
		height: 1.3em;
	}

	/*
	Size modifiers
	*/

	.Heading--micro & {
		font-size: em(12, 18);
		@include bp(medium) {
			font-size: em(14, 20);
		}
		@include bp(larger) {
			font-size: em(14, 22);
		}
	}

	.Heading--smaller & {
		font-size: em(18, 22);
	}

	.Heading--small & {
		font-size: 1em;
	}

	.Heading--large & {
		font-size: em(36, 22);
	}

	.Heading--larger & {
		font-size: em(40, 22);
	}

	.Heading--huge & {
		font-size: em(45, 22);
		line-height: 1.15;
		@include bp(larger) {
			font-size: em(55, 22);
		}
	}

	.Heading--ultra & {
		font-size: em(45, 22);
		line-height: 1.1;
		@include bp(small) {
			font-size: 8vw;
			line-height: 1;
		}
		@include bp(ultra) {
			font-size: em(134.4, 22);
		}

		.Billboard--small & {
			@include bp(medium) {
				font-size: 8vw;
			}
			@include bp(ultra) {
				font-size: em(67.2, 22);
			}
		}
	}

	.Heading--fluid & {
		font-size: em(60, 22);
		line-height: 1;
		@include bp(small) {
			font-size: 10vw;
		}
		@include bp(ultra) {
			font-size: em(168, 22);
		}

		.Billboard--small & {
			@include bp(medium) {
				font-size: 5vw;
			}
			@include bp(ultra) {
				font-size: em(84, 22);
			}
		}

		small {
			font-size: 0.5em;
		}
	}

	/*
	Style modifiers
	*/

	.Heading--album & {
		font-size: em(50, 22);
		line-height: 1.1;
	}

	.Heading--billboard &,
	.Heading--hero & {
		font-family: $font-expanded;
		font-weight: 900;
	}
}

.Heading-headline {
	font-weight: 500;

	/*
	Style modifiers
	*/

	.Heading--regular & {
		font-weight: 400;
	}

	.Heading--compressed & {
		font-family: $font-compressed;
		font-weight: 700;
	}

	.Heading--extended & {
		font-family: $font-extended;
		font-weight: 700;
	}

	.Heading--expanded & {
		font-family: $font-expanded;
		font-weight: 900;
	}

	.Heading--interview & {
		font-size: 1.3em;
		font-weight: 400;
		line-height: 1.3;
	}

	.Heading--billboard.Heading--fluid & {
		font-size: em(20, 22);
		line-height: 1;
		@include bp(small) {
			font-size: 3vw;
		}
		@include bp(ultra) {
			font-size: em(50.4, 22);
		}

		.Billboard--small & {
			@include bp(medium) {
				font-size: em(20, 22);
			}
			@include bp(larger) {
				font-size: 1.5vw;
			}
			@include bp(ultra) {
				font-size: em(25.2, 22);
			}
		}
	}

	.Heading--hero.Heading--ultra & {
		font-family: $font-extended;
		font-size: em(40, 22);
		font-weight: 700;
	}
}

.Heading-subline {

	/*
	Style modifiers
	*/

	.Heading--interview & {
		font-family: $font-extended;
		font-weight: 700;
		hyphens: auto;
	}
}
