.Track {
	padding: em(10.5, 18) 0;
	@include bp(medium) {
		padding-top: em(9, 20);
		padding-bottom: em(9, 20);
	}
	@include bp(larger) {
		padding-top: em(7.5, 22);
		padding-bottom: em(7.5, 22);
	}
}

.Track--large {
	padding-top: em(8.5, 18);
	padding-bottom: em(8.5, 18);
	@include bp(medium) {
		padding-top: em(7, 20);
		padding-bottom: em(7, 20);
	}
	@include bp(larger) {
		padding-top: em(5, 22);
		padding-bottom: em(5, 22);
	}
}

.Track-number,
.Track-player {
	display: block;
	float: left;
}

.Track-number {
	text-align: right;
	padding-right: em(12, 18);
	width: em(32, 18);
	@include bp(medium) {
		padding-right: em(12, 20);
		width: em(36, 20);
	}
	@include bp(larger) {
		padding-right: em(18, 22);
		width: em(42, 22);
	}
}

.Track-player {
	margin: em(-10.5, 18) 0 em(-10.5, 18) em(-6, 18);
	padding-right: em(6, 18);
	width: em(54, 18);
	height: em(48, 18);
	@include bp(medium) {
		margin-top: em(-9, 20);
		margin-bottom: em(-9, 20);
		margin-left: em(-6, 20);
		padding-right: em(6, 20);
		width: em(54, 20);
		height: em(48, 20);
	}
	@include bp(larger) {
		margin-top: em(-7.5, 22);
		margin-bottom: em(-7.5, 22);
		margin-left: em(-6, 22);
		padding-right: em(12, 22);
		width: em(60, 22);
		height: em(48, 22);
	}

	audio {
		display: none;
	}

	.Track--large & {
		margin-top: em(-8.5, 18);
		margin-bottom: em(-8.5, 18);
		@include bp(medium) {
			margin-top: em(-7, 20);
			margin-bottom: em(-7, 20);
		}
		@include bp(larger) {
			margin-top: em(-5, 22);
			margin-bottom: em(-5, 22);
		}
	}
}

.Track-body {
	display: block;

	.Track--numbered & {
		margin-left: em(32, 18);
		@include bp(medium) {
			margin-left: em(36, 20);
		}
		@include bp(larger) {
			margin-left: em(42, 22);
		}
	}

	.Track--playable & {
		margin-left: em(48, 18);
		@include bp(medium) {
			margin-left: em(48, 20);
		}
		@include bp(larger) {
			margin-left: em(54, 22);
		}
	}

	.Track--numbered.Track--playable & {
		margin-left: em(80, 18);
		@include bp(medium) {
			margin-left: em(84, 20);
		}
		@include bp(larger) {
			margin-left: em(96, 22);
		}
	}
}

.Track-title {
	padding: 0.72em 0;
	@include bp(medium) {
		padding-top: 0.6em;
		padding-bottom: 0.6em;
	}
	@include bp(larger) {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	.Track--large & {
		padding-top: 0.405em;
		padding-bottom: 0.405em;
		font-size: 1.3em;
		line-height: 1.3;
		@include bp(medium) {
			padding-top: 0.325em;
			padding-bottom: 0.3275em;
		}
		@include bp(larger) {
			padding-top: 0.225em;
			padding-bottom: 0.2275em;
		}
	}

	.isActive & {
		color: $color-secondary;

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			color: $color-primary;
		}
	}
}

a.Track-title {
	transition: color 150ms ease-out;

	.Track--song &,
	&:hover {
		color: $color-secondary;

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			color: $color-primary;
		}
	}
}

.Track-title-label {

	.Track--song & {
		background-image: linear-gradient(to bottom, transparent unit(27, 29), currentColor unit(27, 29));
		@include bp(medium) {
			background-image: linear-gradient(to bottom, transparent unit(29, 31), currentColor unit(29, 31));
		}
		@include bp(larger) {
			background-image: linear-gradient(to bottom, transparent unit(33, 35), currentColor unit(33, 35));
		}
	}
}

.Track-icon {
	position: relative;
	display: inline-block;
	vertical-align: top;
	top: em(4, 16);
	padding-left: em(6, 16);
	margin-bottom: em(-4, 16);
	color: $color-light-gray;
	font-size: em(16, 18);
	line-height: 1.25;
	@include bp(medium) {
		font-size: em(16, 20);
		top: em(6, 16);
		margin-bottom: em(-6, 16);
	}
	@include bp(larger) {
		font-size: em(16, 22);
		top: em(8, 16);
		margin-bottom: em(-8, 16);
	}
}

.Track-icon--bonus {
	color: $color-secondary;

	.Theme--red &,
	.ThemeBlock--red &,
	.ColorBlock--secondary &,
	.Color--secondary & {
		color: $color-primary;
	}
}
