.Icon {
	position: relative;
	display: inline-block;
	vertical-align: top;
	line-height: 1.25;
	width: 1.25em;
	height: 1.25em;

	> img {
		display: block;
		width: 100%;
		height: 100%;
	}

	> svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}

.Icon--flag {

	> img {
		height: auto;
		margin-top: 0.3125em;
	}
}

.Icon--lyrics,
.Icon--menu {

	&::after,
	&::before {
		content: "";
	}

	span,
	&::after,
	&::before {
		display: block;
		position: absolute;
		left: 0.125em;
		height: 0.125em;
		width: 1em;
		background: currentColor;
	}

	&::before {
		top: 0.1875em;
	}

	span {
		top: 0.5625em;
	}

	&::after {
		top: 0.9375em;
	}
}

.Icon--lyrics {

	&::before {
		top: 0.3125em;
	}

	span {
		top: 0.625em;
	}

	&::after {
		top: 0.9375em;
	}
}

.Icon--menu {

	span,
	&::after,
	&::before {
		opacity: 1;
		transform: rotate(0deg);
		transform-origin: left center;
		transition: 200ms ease-in-out;
	}

	.isMenuOpen & {

		&::before {
			transform: rotate(45deg);
			top: 0.25em;
			left: 0.25em;
		}

		span {
			width: 0%;
			opacity: 0;
		}

		&::after {
			transform: rotate(-45deg);
			top: 0.9375em;
			left: 0.25em;
		}
	}
}

.Icon--play {
	overflow: hidden;
	transform: translate3d(0.125em, 0, 0);
	transition: transform 250ms ease;

	.isPlaying & {
		transform: translate3d(0, 0, 0);
	}

	.Icon--play--left,
	.Icon--play--right {
		position: absolute;
		display: block;
		top: em(2, 16);
		width: em(6, 16);
		height: em(16, 16);
		background-color: currentColor;
		transition: width 250ms ease-out;

		.isPlaying & {
			width: em(4, 16);
		}
	}

	.Icon--play--left {
		left: em(4, 16);
	}

	.Icon--play--right {
		right: em(4, 16);
	}

	.Icon--play--top,
	.Icon--play--bottom {
		position: absolute;
		left: em(4, 16);
		border-width: em(8, 16) em(13, 16) em(8, 16) 0;
		border-style: solid;
		border-color: $color-secondary;
		transition: transform 250ms ease-out;

		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			border-color: $color-primary;
		}
	}

	.Icon--play--top {
		bottom: em(10, 16);

		&,
		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			border-bottom-color: transparent;
		}

		.isPlaying & {
			transform: translate3d(0, -50%, 0);
		}
	}

	.Icon--play--bottom {
		top: em(10, 16);

		&,
		.Theme--red &,
		.ThemeBlock--red &,
		.ColorBlock--secondary &,
		.Color--secondary & {
			border-top-color: transparent;
		}

		.isPlaying & {
			transform: translate3d(0, 50%, 0);
		}
	}
}
