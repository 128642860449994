.SongDetails {
	padding-bottom: ms(4em);
	@include bp(larger) {
		padding-bottom: ms(6em);
	}
}

.SongDetails-inner {
	@include bp(large) {
		@include clearfix;
	}
}

.SongDetails-body {
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		float: left;
		margin-left: 16.66666%;
		margin-right: 0;
		width: 66.66666%;
	}
	@include bp(larger) {
		margin-left: 20%;
		width: 60%;
	}
	@include bp(huge) {
		margin-left: 25%;
		width: 50%;
	}
	@include bp(ultra) {
		margin-left: 28.57142%;
		width: 42.85714%;
	}
}

.SongDetails-footer {
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		float: right;
		margin-left: 0;
		margin-right: 0;
		width: 16.66666%;
	}
	@include bp(larger) {
		width: 20%;
	}
	@include bp(huge) {
		width: 25%;
	}
	@include bp(ultra) {
		width: 28.57142%;
	}

	* + & {
		@include bp(null, small) {
			margin-top: ms(1em);
		}
		@include bp(small, large) {
			margin-top: ms(2em);
		}
	}
}

.SongDetails-details,
.SongDetails-master,
.SongDetails-songbook {
	margin: 0 ms(0.5em);
}

.SongDetails-details {
	@include bp(large) {
		text-align: right;
	}
}

.SongDetails-master,
.SongDetails-songbook,
.SongDetails-variants {

	* + & {
		margin-top: ms(2em);
		@include bp(larger) {
			margin-top: ms(3em);
		}
	}
}

.SongDetails-songbook {
	@include bp(small) {
		padding-left: em(48, 18);
	}
	@include bp(medium) {
		padding-left: em(48, 20);
	}
	@include bp(larger) {
		padding-left: em(54, 22);
	}
}
