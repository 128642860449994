.BandMembers {

}

.BandMembers-unit {
	display: block;

	& + & {
		margin-top: ms(1em);
		@include bp(medium) {
			margin-top: ms(-1em);
		}
		@include bp(large) {
			margin-top: ms(-2em);
		}
	}

	&:nth-child(4n+1) {
		margin-left: 25%;
		margin-right: 8.33333%;

		.BandMembers-item-button {
			transform: translateX(-75%) translateY(-50%);
		}
	}

	&:nth-child(4n+2) {
		margin-right: 41.66666%;

		.BandMembers-item-button {
			left: 100%;
		}
	}

	&:nth-child(4n+3) {
		margin-left: 41.66666%;

		.BandMembers-unit + & {
			margin-top: ms(1em);
		}

		.BandMembers-item-button {
			transform: translateX(-75%) translateY(-50%);
		}
	}

	&:nth-child(4n+4) {
		margin-right: 41.66666%;

		.BandMembers-item {
			margin-left: 0;
			margin-right: ms(1em);
			@include bp(smaller) {
				margin-left: ms(-0.25em);
				margin-right: ms(1.25em);
			}
			@include bp(small) {
				margin-left: ms(-0.5em);
				margin-right: ms(1.5em);
			}
			@include bp(medium) {
				margin-left: ms(-1em);
				margin-right: ms(2em);
			}
			@include bp(larger) {
				margin-left: ms(-1.5em);
				margin-right: ms(2.5em);
			}
		}

		.BandMembers-item-button {
			left: 100%;
		}
	}
}

.BandMembers-item {
	position: relative;
	display: block;
	margin: 0 ms(0.5em);

	&:hover,
	&:active {

		.Button {
			border-color: $color-secondary;
			color: $color-secondary;
		}
	}
}

.BandMembers-item-image {
	overflow: hidden;
}

.BandMembers-item-image-inner {
	transform: translateZ(0);
	transition: transform 2000ms ease;

	.BandMembers-item:hover & {
		transform: translateZ(0) scale(1.1);
	}
}

.BandMembers-item-button {
	position: absolute;
	top: 50%;
	transform: translateX(-25%) translateY(-50%);
}
