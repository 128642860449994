@font-face {
	font-family: "GTAmerica";
	font-style: normal;
	font-weight: 400;
	src: url("../assets/webfonts/GT-America-Standard-Regular.eot");
	src: url("../assets/webfonts/GT-America-Standard-Regular.eot?#iefix") format("embedded-opentype"),
		url("../assets/webfonts/GT-America-Standard-Regular.woff") format("woff");
}

@font-face {
	font-family: "GTAmerica";
	font-style: normal;
	font-weight: 500;
	src: url("../assets/webfonts/GT-America-Standard-Medium.eot");
	src: url("../assets/webfonts/GT-America-Standard-Medium.eot?#iefix") format("embedded-opentype"),
		url("../assets/webfonts/GT-America-Standard-Medium.woff") format("woff");
}

@font-face {
	font-family: "GTAmericaCompressed";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/webfonts/GT-America-Trial-Compressed-Bold.otf") format("opentype");
}

@font-face {
	font-family: "GTAmericaExtended";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/webfonts/GT-America-Extended-Bold.eot");
	src: url("../assets/webfonts/GT-America-Extended-Bold.eot?#iefix") format("embedded-opentype"),
		url("../assets/webfonts/GT-America-Extended-Bold.woff") format("woff");
}

@font-face {
	font-family: "GTAmericaExpanded";
	font-style: normal;
	font-weight: 900;
	src: url("../assets/webfonts/GT-America-Expanded-Black.eot");
	src: url("../assets/webfonts/GT-America-Expanded-Black.eot?#iefix") format("embedded-opentype"),
		url("../assets/webfonts/GT-America-Expanded-Black.woff") format("woff");
}
