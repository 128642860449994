.Campaign {
	padding: ms(2em) 0 ms(4em);
	@include bp(larger) {
		padding-top: ms(3em);
		padding-bottom: ms(6em);
	}
}

.Campaign-header {

}

.Campaign-outline,
.Campaign-body {

	* + & {
		margin-top: ms(1.5em);
		@include bp(medium) {
			margin-top: ms(2em);
		}
	}
}

.Campaign-outline {
	@include bp(small) {
		margin-right: 16.66666%;
	}
	@include bp(medium) {
		margin-right: 20%;
	}
	@include bp(large) {
		margin-right: 33.33333%;
	}
	@include bp(larger) {
		margin-right: 40%;
	}
	@include bp(huge) {
		margin-right: 50%;
	}
	@include bp(ultra) {
		margin-right: 57.14285%;
	}
}

.Campaign-body {
	@include bp(small) {
		position: relative;
		z-index: 1;
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		margin-left: 16.66666%;
		margin-right: 16.66666%;
	}
	@include bp(larger) {
		margin-left: 20%;
		margin-right: 20%;
	}
	@include bp(huge) {
		margin-left: 25%;
		margin-right: 25%;
	}
	@include bp(ultra) {
		margin-left: 28.57142%;
		margin-right: 28.57142%;
	}
}

.Campaign-visual {
	@include bp(small) {
		position: relative;
		z-index: 0;
	}

	.Campaign-body + & {
		@include bp(small) {
			margin-top: ms(-1em);
		}
		@include bp(medium) {
			margin-top: ms(-1.5em);
		}
	}
}

.Campaign-heading,
.Campaign-excerpt,
.Campaign-cta {
	padding: 0 ms(0.5em);
}

.Campaign-heading {
	text-align: center;
}

.Campaign-excerpt {

	* + & {
		margin-top: ms(0.75em);
		@include bp(medium) {
			margin-top: ms(1em);
		}
	}
}

.Campaign-cta {
	text-align: center;

	* + & {
		margin-top: ms(1.5em);
		@include bp(medium) {
			margin-top: ms(2em);
		}
	}
}
