.Grid {

}

.Grid--balanced {
	letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
	*letter-spacing: normal; /* reset IE < 8 */
	*word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
	text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

	/*
	Sets the font stack to fonts known to work properly with the above letter
	and word spacings. See: https://github.com/yahoo/pure/issues/41/

	The following font stack makes Grids work on all known environments.

	* FreeSans: Ships with many Linux distros, including Ubuntu

	* Arimo: Ships with Chrome OS. Arimo has to be defined before Helvetica and
	  Arial to get picked up by the browser, even though neither is available
	  in Chrome OS.

	* Droid Sans: Ships with all versions of Android.

	* Helvetica, Arial, sans-serif: Common font stack on OS X and Windows.
	*/
	font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

	/* Use flexbox when possible to avoid `letter-spacing` side-effects. */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	/* Prevents distributing space between rows */
	align-content: flex-start;
	justify-content: center;
	text-align: center;

	table & {
		/*
		IE10 display: -ms-flexbox (and display: flex in IE 11) does not work inside a
		table; fall back to block and rely on font hack
		*/
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
		}
	}

	.Grid-unit {
		display: inline-block;
		*display: inline; /* IE < 8: fake inline-block */
		zoom: 1;
		letter-spacing: normal;
		word-spacing: normal;
		vertical-align: top;
		text-align: left;
		text-rendering: auto;
		/*
		Resets the font family back to the OS/browser's default sans-serif font,
		this the same font stack that Normalize.css sets for the `body`.
		*/
		font-family: $font-primary;
	}
}

/*
Opera as of 12 on Windows needs word-spacing.
The ".opera-only" selector is used to prevent actual prefocus styling and is not
required in markup.
*/
.opera-only :-o-prefocus,
.Grid--balanced {
	word-spacing: -0.43em;
}

.Grid--floated {
	@include bp(medium) {
		@include clearfix;
	}

	.Grid-unit {
		@include bp(medium) {
			width: 50%;
			float: left;
			clear: left;
		}
	}

	.Grid-unit:nth-child(4n+2),
	.Grid-unit:nth-child(4n+3),
	.Grid-unit--right {
		@include bp(medium) {
			float: right;
			clear: right;
		}
	}

	.Grid-unit.Grid-unit--left {
		@include bp(medium) {
			float: left;
			clear: left;
		}
	}
}

.Grid--promos {
	@include bp(medium) {
		@include clearfix;
	}

	.Grid-unit {
		@include bp(medium) {
			width: 50%;
			float: left;
		}
	}
}

.Grid--tours {
	@include bp(medium) {
		margin-left: ms(-1em);
		margin-right: ms(-1em);
	}
	@include bp(large) {
		margin-left: ms(-1.5em);
		margin-right: ms(-1.5em);
	}
	@include bp(larger) {
		margin-left: ms(-2em);
		margin-right: ms(-2em);
	}
}

.Grid-unit {
	margin-bottom: ms(1.5em);
	width: 100%;
	@include bp(large) {
		margin-bottom: ms(2.5em);
	}

	.Grid--articles & {
		margin-bottom: ms(2.5em);
		@include bp(medium) {
			margin-bottom: ms(2em);
		}
		@include bp(large) {
			margin-bottom: ms(4em);
		}
		@include bp(larger) {
			margin-bottom: ms(5em);
		}
	}

	.Grid--images &,
	.Grid--medium & {
		width: 50%;
		@include bp(medium) {
			width: 33.33333%;
		}
		@include bp(huge) {
			width: 25%;
		}
	}

	.Grid--images & {
		padding-left: ms(0.5em);
		padding-right: ms(0.5em);
		margin-bottom: ms(1em);
	}

	.Grid--media & {
		@include bp(large) {
			margin-bottom: ms(2.5em);
		}
	}

	.Grid--promos & {
		margin-bottom: 0;
		width: 100%;

		&.Grid-unit--small {
			@include bp(medium) {
				width: 50%;
			}
		}
	}

	.Grid--releases & {
		margin-bottom: ms(1em);
		@include bp(large) {
			margin-bottom: ms(1.5em);
		}
	}

	.Grid--tours & {
		margin-bottom: ms(2.5em);
		@include bp(medium) {
			margin-bottom: ms(3em);
		}
		@include bp(large) {
			margin-bottom: ms(4em);
		}
		@include bp(larger) {
			margin-bottom: ms(5em);
		}
	}

	.Grid--large &,
	.Grid--larger & {
		@include bp(medium) {
			width: 50%;
		}
	}

	.Grid--large & {
		@include bp(huge) {
			width: 33.33333%;
		}
	}

	&.Grid-unit--full {
		width: 100%;
	}
}

.Grid--single {

	&.Grid--floated {
		@include bp(medium) {
			margin: 0 25%;
		}
	}

	&.Grid--large {
		@include bp(medium) {
			margin: 0 25%;
		}
		@include bp(huge) {
			margin: 0 33.33333%;
		}
	}

	.Grid-unit {
		width: 100%;
	}
}

.GridScroller {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	@include bp(smaller, small) {
		margin-left: ms(-0.25em);
		margin-right: ms(-0.25em);
	}
	@include bp(small, medium) {
		margin-left: ms(-0.5em);
		margin-right: ms(-0.5em);
	}
	@include bp(medium, larger) {
		margin-left: ms(-1em);
		margin-right: ms(-1em);
	}
	@include bp(larger, ultra) {
		margin-left: ms(-1.5em);
		margin-right: ms(-1.5em);
	}

	.Grid--balanced {
		flex-wrap: nowrap;
		justify-content: flex-start;
		@include bp(smaller, small) {
			margin-left: ms(0.25em);
			margin-right: ms(0.25em);
		}
		@include bp(small, medium) {
			margin-left: ms(0.5em);
			margin-right: ms(0.5em);
		}
		@include bp(medium, larger) {
			margin-left: ms(1em);
			margin-right: ms(1em);
		}
		@include bp(larger, ultra) {
			margin-left: ms(1.5em);
			margin-right: ms(1.5em);
		}

		.Grid-unit {
			flex: 1;
		}

		&.Grid--medium {

			.Grid-unit {
				min-width: 66.66666%;
				max-width: 100%;
				width: auto;
				@include bp(medium) {
					min-width: 40%;
					max-width: 50%;
				}
				@include bp(larger) {
					min-width: 22.22222%;
					max-width: 25%;
				}
			}
		}

		&.Grid--playlist {
			@include bp(smaller, ultra) {
				margin-left: 0;
				margin-right: 0;
			}

			.Grid-unit {
				margin-bottom: 0;
				min-width: 40%;
				max-width: 50%;
				width: auto;
				@include bp(medium) {
					min-width: 28.57142%;
					max-width: 33.33333%;
				}
				@include bp(larger) {
					min-width: 22.22222%;
					max-width: 25%;
				}
			}
		}

		&.Grid--releases {

			.Grid-unit {
				margin-bottom: 0;
				padding-bottom: ms(1em);
				@include bp(large) {
					padding-bottom: ms(1.5em);
				}
			}
		}
	}
}
