.Breadcrumb {
	margin: 0 ms(0.5em);
}

.Breadcrumb-item {
	position: relative;
	display: inline-block;
	font-family: $font-extended;
	font-size: 1em * (17 / 22);
	font-style: normal;
	font-weight: 700;
	line-height: ms(1, 17 / 22);

	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-top-style: solid;
		border-top-width: 1em * (2 / 16) / (17 / 22);
		@include bp(medium) {
			border-top-width: 1em * (3 / 20) / (17 / 22);
		}
		@include bp(medium) {
			border-top-width: 1em * (3 / 22) / (17 / 22);
		}
	}
}
