.Container {
	margin: 0 auto;
	max-width: em(1680, 22);
	@include bp(smaller) {
		padding-left: ms(0.25em);
		padding-right: ms(0.25em);
	}
	@include bp(small) {
		padding-left: ms(0.5em);
		padding-right: ms(0.5em);
	}
	@include bp(medium) {
		padding-left: ms(1em);
		padding-right: ms(1em);
	}
	@include bp(larger) {
		padding-left: ms(1.5em);
		padding-right: ms(1.5em);
	}
}

.Container--fluid {
	max-width: none;
}

.Container--split {
	max-width: em(840, 22);

	&.Container--left {
		@include bp(medium) {
			margin-right: 0;
			padding-right: 0;
		}
	}

	&.Container--right {
		@include bp(medium) {
			margin-left: 0;
			padding-left: 0;
		}
	}
}

.Container-full {
	@include bp(large) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(larger) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(huge) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(ultra) {
		margin-left: 14.28571%;
		margin-right: 14.28571%;
	}
}

.Container-center {
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		margin-left: 16.66666%;
		margin-right: 16.66666%;
	}
	@include bp(larger) {
		margin-left: 20%;
		margin-right: 20%;
	}
	@include bp(huge) {
		margin-left: 25%;
		margin-right: 25%;
	}
	@include bp(ultra) {
		margin-left: 28.57142%;
		margin-right: 28.57142%;
	}
}

.Container-left {
	@include bp(small) {
		margin-right: 16.66666%;
	}
	@include bp(medium) {
		margin-right: 20%;
	}
	@include bp(large) {
		margin-right: 33.33333%;
	}
	@include bp(larger) {
		margin-right: 40%;
	}
	@include bp(huge) {
		margin-right: 50%;
	}
	@include bp(ultra) {
		margin-right: 57.14285%;
	}
}

.Container-right {
	@include bp(small) {
		margin-left: 16.66666%;
	}
	@include bp(medium) {
		margin-left: 20%;
	}
	@include bp(large) {
		margin-left: 33.33333%;
	}
	@include bp(larger) {
		margin-left: 40%;
	}
	@include bp(huge) {
		margin-left: 50%;
	}
	@include bp(ultra) {
		margin-left: 57.14285%;
	}
}
