.Register {
	@include bp(small) {
		column-count: 2;
		column-gap: 0;
	}
	@include bp(large) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
}

.Register-group {
	@include bp(small) {
		break-inside: avoid;
	}
}

.Register-label,
.Register-item {
	display: block;
	padding: 0 ms(0.5em);
}

.Register-label {
	color: $color-secondary;
}

.Register-item {
	padding-left: ms(1em);
	text-indent: ms(-0.5em);
}
