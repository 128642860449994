.Poster {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.Poster-image {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	.Poster--top-left & {
		background-position: left top;
	}

	.Poster--top-center & {
		background-position: center top;
	}

	.Poster--top-right & {
		background-position: right top;
	}

	.Poster--center-left & {
		background-position: left center;
	}

	.Poster--center-center & {
		background-position: center center;
	}

	.Poster--center-right & {
		background-position: right center;
	}

	.Poster--bottom-left & {
		background-position: left bottom;
	}

	.Poster--bottom-center & {
		background-position: center bottom;
	}

	.Poster--bottom-right & {
		background-position: right bottom;
	}

	.Poster--composing & {
		top: -5%;
		left: -5%;
		right: -5%;
		bottom: -5%;
		background-size: auto 100%;
	}
}

.Poster--video {

	video {
		position: absolute;
		top: 0;
		left: 50%;
		right: 0;
		bottom: 0;
		height: 100%;
		width: auto;
		transform: translateX(-50%);
	}

	&.Poster--fitWidth {

		video {
			left: 0;
			top: 50%;
			width: 100%;
			height: auto;
			transform: translateY(-50%);
		}
	}
}
