.Quote {
	margin: 0 ms(0.5em);
	padding: 0;

	> p,
	.Quote-body {
		font-size: 1.3em;
		line-height: 1.3;

		a {
			background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-secondary unit(28, 29));
			text-decoration: none;
			transition-duration: 200ms;
			transition-property: background-color, color;
			transition-timing-function: ease-out;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			.Theme--red &,
			.ThemeBlock--red & {
				background-image: linear-gradient(to bottom, transparent unit(28, 29), $color-primary unit(28, 29));
			}

			&:hover,
			&:focus,
			&:active {
				outline: 0;
				color: $color-secondary;

				.Theme--red &,
				.ThemeBlock--red & {
					color: $color-primary;
				}
			}

			&:active {
				transition-duration: 0ms;
			}
		}

		em {
			font-style: normal;

			html[lang="de"] & {

				&::before {
					content: "‚";
				}

				&::after {
					content: "‘";
				}
			}

			html[lang="en"] & {

				&::before {
					content: "‘";
				}

				&::after {
					content: "’";
				}
			}

			html[lang="es"] & {

				&::before {
					content: "“";
				}

				&::after {
					content: "”";
				}
			}
		}
	}

	&.Quote--center {

		> p,
		.Quote-body {
			text-align: center;
		}
	}

	&.Quote--left {

		> p,
		.Quote-body {
			text-align: left;
		}
	}

	&.Quote--right {

		> p,
		.Quote-body {
			text-align: right;
		}
	}
}

.Quote--large,
.Quote--larger {

	> p,
	.Quote-body {
		font-size: 1em * (36 / 22);

		a {
			background-image: linear-gradient(to bottom, transparent unit(42, 43), $color-secondary unit(42, 43));

			.Theme--red &,
			.ThemeBlock--red & {
				background-image: linear-gradient(to bottom, transparent unit(42, 43), $color-primary unit(42, 43));
			}
		}
	}
}

.Quote--larger {

	> p,
	.Quote-body {
		@include bp(medium) {
			font-size: 1em * (40 / 22);
		}

		a {
			@include bp(medium) {
				background-image: linear-gradient(to bottom, transparent unit(47, 48), $color-secondary unit(47, 48));
			}

			.Theme--red &,
			.ThemeBlock--red & {
				@include bp(medium) {
					background-image: linear-gradient(to bottom, transparent unit(47, 48), $color-primary unit(47, 48));
				}
			}
		}
	}
}

.Quote--extended {

	> p,
	.Quote-body {
		font-family: $font-extended;
		font-weight: 700;
	}
}

.Quote-caption {
	display: block;
	padding-top: ms(1em);
	font-size: inherit;
	font-style: inherit;

	.Quote--center &,
	.Quote--right & {
		text-align: right;
	}
}
