.ReleaseDetails {
	position: relative;
	@include bp(large) {
		@include clearfix;
	}
}

.ReleaseDetails-aside {
	@include bp(large) {
		position: relative;
		z-index: 1;
	}

	&.isSticky {
		@include bp(large) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			transform: translateZ(0);
		}

		&.isMiddle {
			@include bp(large) {
				position: fixed;
			}
		}

		&.isBottom {
			@include bp(large) {
				top: auto;
				bottom: 0;
			}
		}
	}
}

.ReleaseDetails-aside-inner {
	@include bp(large) {
		float: right;
		margin-top: -33.33333%;
		padding-top: ms(1.5em);
		padding-bottom: ms(1.5em);
		width: 41.66666%;
	}
	@include bp(larger) {
		padding-top: ms(2em);
		padding-bottom: ms(2.6em);
	}
	@include bp(huge) {
		margin-top: -29.16666%;
		margin-right: 8.33333%;
		width: 33.33333%;
	}

	.isSticky & {
		@include bp(large) {
			float: none;
			margin-top: 0;
			margin-left: 58.33333%;
			width: auto;
		}
	}
}

.ReleaseDetails-artwork {
	//box-shadow: 0 0 ms(0.5em) transparentize($color-primary, 0.8);
	@include bp(null, large) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 8.33333% 16.66666%;
	}
	@include bp(smaller, small) {
		left: ms(0.25em);
		right: ms(0.25em);
	}
	@include bp(small, medium) {
		left: ms(0.5em);
		right: ms(0.5em);
	}
	@include bp(medium, large) {
		left: ms(1em);
		right: ms(1em);
	}
	@include bp(large) {
		margin-left: ms(0.5em);
		margin-right: ms(0.5em);
	}

	.ReleaseDetails--book & .ResponsiveMedia-body,
	.ReleaseDetails--musicalFilm & .ResponsiveMedia-body {
		@include bp(large) {
			margin-right: 0;
		}
	}
}

.ReleaseDetails-distributors,
.ReleaseDetails-details {

	.ReleaseDetails-aside & {
		margin-top: ms(0.5em);
		@include bp(smaller) {
			margin-top: ms(0.75em);
		}
		@include bp(small) {
			margin-top: ms(1em);
		}
		@include bp(large) {
			margin-top: ms(1.125em);
			text-align: right;
		}
	}
}

.ReleaseDetails-details {
	margin: 0 ms(0.5em);
}

.ReleaseDetails-visual {
	position: relative;
	padding-bottom: 83.33333%;
	z-index: 0;
	opacity: 0;
	@include bp(large) {
		padding-bottom: 33.33333%;
	}
	@include bp(huge) {
		padding-bottom: 29.16666%;
	}
}

.ReleaseDetails-visual-inner {
	position: absolute;
	top: 41.66666%;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: $color-off-white;
	@include bp(smaller, small) {
		margin-left: ms(-0.25em);
		margin-right: ms(-0.25em);
	}
	@include bp(small, medium) {
		margin-left: ms(-0.5em);
		margin-right: ms(-0.5em);
	}
	@include bp(medium, larger) {
		margin-left: ms(-1em);
		margin-right: ms(-1em);
	}
	@include bp(large) {
		top: 0;
	}
	@include bp(larger, ultra) {
		margin-left: ms(-1.5em);
		margin-right: ms(-1.5em);
	}
	@include bp(ultra) {
		margin-left: ms(0.5em);
		margin-right: ms(0.5em);
	}
}

.ReleaseDetails-header {
	@include bp(large) {
		position: relative;
		margin-top: -33.33333%;
		margin-right: 50%;
		padding-bottom: 33.33333%;
		z-index: 2;
	}
	@include bp(huge) {
		margin-top: -29.16666%;
		margin-left: 8.33333%;
		padding-bottom: 29.16666%;
	}
}

.ReleaseDetails-heading {
	margin: 0 ms(0.5em);
	@include bp(large) {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: ms(0.75em);
	}
}

.ReleaseDetails-body {
	padding: ms(1em) 0;
	@include bp(small) {
		padding-bottom: ms(1em);
	}
	@include bp(large) {
		position: relative;
		z-index: 2;
		margin-right: 50%;
		padding-bottom: ms(2em);
	}
	@include bp(huge) {
		margin-left: 8.33333%;
	}
}

.ReleaseDetails-tracks {

	* + & {
		margin-top: ms(1em);
		@include bp(small) {
			margin-top: ms(1.5em);
		}
		@include bp(large) {
			margin-top: ms(1.5em);
		}
	}
}

.ReleaseDetails-footer {

}
