.Page {
	position: relative;
	z-index: 0;
	overflow: hidden;
	padding-top: em(116, 18);
	@include bp(medium, large) {
		padding-top: em(154, 20);
	}
	@include bp(large) {
		padding-top: em(208, 20);
	}
	@include bp(larger) {
		padding-top: em(216, 22);
	}
}

.Page-toolbar {
	position: relative;
	z-index: 1;
}

.Page-toolbar-inner {
	position: relative;
	@include bp(medium) {
		@include clearfix;
	}
}

.Page-nav {
	position: absolute;
	z-index: 1;
	top: em(-40, 18);
	left: 0;
	@include bp(medium) {
		top: em(-41, 20);
	}
	@include bp(larger) {
		top: em(-44, 22);
	}
}

.Page-button {
	padding-bottom: ms(2em);
	@include bp(larger) {
		padding-bottom: ms(3em);
	}
}

.Page-button-inner {
	margin: 0 ms(0.5em);
	text-align: center;
}

.Page-filter {
	@include bp(medium) {
		position: relative;
		z-index: 0;
		margin-top: em(-30, 20);
		text-align: right;
	}
	@include bp(large) {
		margin-right: ms(1em);
	}
	@include bp(larger) {
		margin-top: em(-30, 22);
	}
}

.Page-header-shadow {
	position: fixed;
	z-index: -1;
	left: 0;
	right: 0;
	color: #222222;
	//opacity: 0.1;
}

.Page-header {
	padding-bottom: ms(2.25em);
	@include bp(medium) {
		padding-bottom: ms(3em);
	}

	.Page-toolbar + & {
		margin-top: 0;
	}

	.Page--404 &,
	.Page--categories &,
	.Page--chronicleIndex & {
		padding-bottom: 0;
	}
}

.Page-editorial {

}

.Page-section {

	* + & {
		margin-top: 0;
	}
}

.Page-section--spaced {
	margin-bottom: ms(1em);
	@include bp(medium) {
		margin-bottom: ms(1.5em);
	}

	* + & {
		margin-top: ms(1em);
		@include bp(medium) {
			margin-top: ms(1.5em);
		}
	}

	.Page-toolbar + & {
		margin-top: 0;
	}
}

.Page--categories {

	.Page-header {

		& + .Page-list {
			@include bp(small) {
				margin-top: -3.636vw;
			}
			@include bp(ultra) {
				margin-top: em(-61, 22);
			}

			.PaginatedList {
				@include bp(small) {
					padding-top: 0;
				}

				.Grid--articles {
					@include bp(medium) {
						padding-top: ms(2em);
					}
					@include bp(large) {
						padding-top: ms(4em);
					}
					@include bp(larger) {
						padding-top: ms(5em);
					}

					.Grid-unit:first-child {
						@include bp(medium) {
							margin-top: ms(-2em);
						}
						@include bp(large) {
							margin-top: ms(-4em);
						}
						@include bp(larger) {
							margin-top: ms(-5em);
						}
					}
				}
			}
		}
	}
}

.Page--home {
	@include bp(null, medium) {
		padding-top: em(88, 18);
	}
	@include bp(medium, large) {
		padding-top: em(136, 20);
	}
}
