.Composing {
	position: relative;
	//pointer-events: none;
}

.Composing-image {
	position: absolute;
	height: auto;
}

.Composing--biography {
	padding-bottom: unit(1400, 1280);
	@include bp(large) {
		padding-bottom: unit(1619, 1280);
	}

	.Composing-image--00 { left: unit( 167, 1280); top: unit(   0, 1400); width: unit( 298, 1280); @include bp(large) { left: unit( 167, 1280); top: unit(  35, 1619); width: unit( 298, 1280); } }
	.Composing-image--01 { left: unit( 870, 1280); top: unit(  20, 1400); width: unit( 389, 1280); @include bp(large) { left: unit( 870, 1280); top: unit(  20, 1619); width: unit( 389, 1280); } }
	.Composing-image--02 { left: unit( 180, 1280); top: unit(  28, 1400); width: unit( 378, 1280); @include bp(large) { left: unit( 180, 1280); top: unit(  38, 1619); width: unit( 378, 1280); } }
	.Composing-image--03 { left: unit( 558, 1280); top: unit(  49, 1400); width: unit( 688, 1280); @include bp(large) { left: unit( 558, 1280); top: unit(  49, 1619); width: unit( 688, 1280); } }
	.Composing-image--04 { left: unit( 952, 1280); top: unit( 243, 1400); width: unit(  23, 1280); @include bp(large) { left: unit( 952, 1280); top: unit( 273, 1619); width: unit(  23, 1280); } }
	.Composing-image--05 { left: unit( 390, 1280); top: unit( 272, 1400); width: unit( 292, 1280); @include bp(large) { left: unit( 430, 1280); top: unit( 346, 1619); width: unit( 242, 1280); } }
	.Composing-image--06 { left: unit(  86, 1280); top: unit( 297, 1400); width: unit(  23, 1280); @include bp(large) { left: unit(  86, 1280); top: unit( 297, 1619); width: unit(  23, 1280); } }
	.Composing-image--07 { left: unit( 698, 1280); top: unit( 402, 1400); width: unit( 726, 1280); @include bp(large) { left: unit( 773, 1280); top: unit( 402, 1619); width: unit( 726, 1280); } }
	.Composing-image--08 { left: unit( 652, 1280); top: unit( 420, 1400); width: unit( 279, 1280); @include bp(large) { left: unit( 727, 1280); top: unit( 420, 1619); width: unit( 279, 1280); } }
	.Composing-image--09 { left: unit( 240, 1280); top: unit( 467, 1400); width: unit( 397, 1280); @include bp(large) { left: unit( 626, 1280); top: unit( 489, 1619); width: unit( 504, 1280); } }
	.Composing-image--10 { left: unit( 725, 1280); top: unit( 538, 1400); width: unit( 755, 1280); @include bp(large) { left: unit( 800, 1280); top: unit( 538, 1619); width: unit( 755, 1280); } }
	.Composing-image--11 { left: unit( 352, 1280); top: unit( 545, 1400); width: unit( 485, 1280); @include bp(large) { left: unit( 732, 1280); top: unit( 700, 1619); width: unit( 325, 1280); } }
	.Composing-image--12 { left: unit( 605, 1280); top: unit( 610, 1400); width: unit( 504, 1280); @include bp(large) { left: unit( 838, 1280); top: unit( 724, 1619); width: unit( 485, 1280); } }
	.Composing-image--13 { left: unit( 960, 1280); top: unit( 765, 1400); width: unit(  23, 1280); @include bp(large) { left: unit( 960, 1280); top: unit( 765, 1619); width: unit(  23, 1280); } }
	.Composing-image--14 { left: unit(  45, 1280); top: unit( 816, 1400); width: unit( 977, 1280); @include bp(large) { left: unit( 583, 1280); top: unit( 994, 1619); width: unit( 926, 1280); } }
	.Composing-image--15 { left: unit( 143, 1280); top: unit( 975, 1400); width: unit( 557, 1280); @include bp(large) { left: unit( 703, 1280); top: unit(1153, 1619); width: unit( 389, 1280); } }
	.Composing-image--16 { left: unit( 984, 1280); top: unit(1045, 1400); width: unit( 267, 1280); @include bp(large) { left: unit(1055, 1280); top: unit(1209, 1619); width: unit( 218, 1280); } }
	.Composing-image--17 { left: unit( 828, 1280); top: unit(1122, 1400); width: unit( 289, 1280); @include bp(large) { left: unit( 965, 1280); top: unit(1371, 1619); width: unit( 210, 1280); } }
}

.Composing--eighties {
	padding-bottom: unit(1659, 1280);

	.Composing-image--00 { left: unit(  85, 1280); top: unit( 992, 1659); width: unit(  49, 1280); }
	.Composing-image--01 { left: unit(  97, 1280); top: unit( 628, 1659); width: unit(1191, 1280); }
	.Composing-image--02 { left: unit( 505, 1280); top: unit( 495, 1659); width: unit( 739, 1280); }
	.Composing-image--03 { left: unit(1038, 1280); top: unit( 875, 1659); width: unit( 265, 1280); }
	.Composing-image--04 { left: unit( 815, 1280); top: unit( 872, 1659); width: unit( 223, 1280); }
	.Composing-image--05 { left: unit( 646, 1280); top: unit(   0, 1659); width: unit( 250, 1280); }
	.Composing-image--06 { left: unit(  97, 1280); top: unit( 210, 1659); width: unit( 500, 1280); }
	.Composing-image--07 { left: unit(  80, 1280); top: unit( 173, 1659); width: unit( 395, 1280); }
	.Composing-image--08 { left: unit( 156, 1280); top: unit( 517, 1659); width: unit( 454, 1280); }
	.Composing-image--09 { left: unit(  34, 1280); top: unit( 620, 1659); width: unit( 524, 1280); }
	.Composing-image--10 { left: unit( 146, 1280); top: unit( 832, 1659); width: unit( 478, 1280); }
	.Composing-image--11 { left: unit( 402, 1280); top: unit( 777, 1659); width: unit( 614, 1280); }
	.Composing-image--12 { left: unit( 255, 1280); top: unit(1277, 1659); width: unit( 382, 1280); }
	.Composing-image--13 { left: unit( 551, 1280); top: unit(1250, 1659); width: unit( 746, 1280); }
}

.Composing--nineties {
	padding-bottom: unit(1357, 1280);

	.Composing-image--00 { left: unit( 689, 1280); top: unit( 232, 1357); width: unit( 562, 1280); }
	.Composing-image--01 { left: unit(-137, 1280); top: unit( 100, 1357); width: unit( 439, 1280); }
	.Composing-image--02 { left: unit( 214, 1280); top: unit( 187, 1357); width: unit(1072, 1280); }
	.Composing-image--03 { left: unit( 436, 1280); top: unit( 669, 1357); width: unit( 272, 1280); }
	.Composing-image--04 { left: unit( 752, 1280); top: unit(   0, 1357); width: unit( 288, 1280); }
	.Composing-image--05 { left: unit( 319, 1280); top: unit(  27, 1357); width: unit( 680, 1280); }
	.Composing-image--06 { left: unit( 957, 1280); top: unit( 811, 1357); width: unit( 219, 1280); }
	.Composing-image--07 { left: unit(1012, 1280); top: unit( 205, 1357); width: unit( 494, 1280); }
	.Composing-image--08 { left: unit( 870, 1280); top: unit( 452, 1357); width: unit( 375, 1280); }
	.Composing-image--09 { left: unit( 692, 1280); top: unit( 687, 1357); width: unit( 290, 1280); }
	.Composing-image--10 { left: unit( 815, 1280); top: unit( 917, 1357); width: unit(  40, 1280); }
	.Composing-image--11 { left: unit( -96, 1280); top: unit( 395, 1357); width: unit( 883, 1280); }
	.Composing-image--12 { left: unit( -30, 1280); top: unit( 635, 1357); width: unit( 589, 1280); }
	.Composing-image--13 { left: unit( 822, 1280); top: unit(1248, 1357); width: unit( 487, 1280); }
	.Composing-image--14 { left: unit( 904, 1280); top: unit(1032, 1357); width: unit( 385, 1280); }
}

.Composing--noughties {
	padding-bottom: unit(2032, 1280);

	.Composing-image--00 { left: unit( 897, 1280); top: unit( 125, 2032); width: unit( 537, 1280); }
	.Composing-image--01 { left: unit( 115, 1280); top: unit(   0, 2032); width: unit( 665, 1280); }
	.Composing-image--02 { left: unit(  80, 1280); top: unit( 123, 2032); width: unit( 755, 1280); }
	.Composing-image--03 { left: unit(  -6, 1280); top: unit( 305, 2032); width: unit(1302, 1280); }
	.Composing-image--04 { left: unit( 960, 1280); top: unit( 604, 2032); width: unit(  63, 1280); }
	.Composing-image--05 { left: unit( 811, 1280); top: unit( 752, 2032); width: unit( 410, 1280); }
	.Composing-image--06 { left: unit(1002, 1280); top: unit(1197, 2032); width: unit( 274, 1280); }
	.Composing-image--07 { left: unit( 772, 1280); top: unit(1491, 2032); width: unit( 434, 1280); }
	.Composing-image--08 { left: unit( 208, 1280); top: unit(1120, 2032); width: unit(1252, 1280); }
	.Composing-image--09 { left: unit( 764, 1280); top: unit( 440, 2032); width: unit( 506, 1280); }
	.Composing-image--10 { left: unit( 313, 1280); top: unit( 900, 2032); width: unit( 555, 1280); }
	.Composing-image--11 { left: unit( 172, 1280); top: unit( 800, 2032); width: unit( 263, 1280); }
	.Composing-image--12 { left: unit( 200, 1280); top: unit( 314, 2032); width: unit( 508, 1280); }
	.Composing-image--13 { left: unit( 608, 1280); top: unit( 200, 2032); width: unit( 239, 1280); }
	.Composing-image--14 { left: unit( 275, 1280); top: unit( 350, 2032); width: unit( 280, 1280); }
}

.Composing--teenies {
	padding-bottom: unit(1375, 1280);

	.Composing-image--00 { left: unit( 700, 1280); top: unit(   0, 1375); width: unit( 645, 1280); }
	.Composing-image--01 { left: unit( 984, 1280); top: unit( 865, 1375); width: unit( 380, 1280); }
	.Composing-image--02 { left: unit(   0, 1280); top: unit( 212, 1375); width: unit( 782, 1280); }
	.Composing-image--03 { left: unit(  21, 1280); top: unit( 673, 1375); width: unit( 557, 1280); }
	.Composing-image--04 { left: unit( 391, 1280); top: unit( 147, 1375); width: unit( 613, 1280); }
	.Composing-image--05 { left: unit( 105, 1280); top: unit( 636, 1375); width: unit( 272, 1280); }
	.Composing-image--06 { left: unit( 630, 1280); top: unit( 509, 1375); width: unit( 651, 1280); }
	.Composing-image--07 { left: unit( 467, 1280); top: unit( 900, 1375); width: unit( 342, 1280); }
	.Composing-image--08 { left: unit( 784, 1280); top: unit(1101, 1375); width: unit( 411, 1280); }
	.Composing-image--09 { left: unit( 970, 1280); top: unit(1150, 1375); width: unit( 390, 1280); }
}

.Composing--twenties {
	padding-bottom: unit(1867, 1280);

	.Composing-image--00 { left: unit( 375, 1280); top: unit( 117, 1867); width: unit( 530, 1280); }
	.Composing-image--01 { left: unit( 720, 1280); top: unit(   0, 1867); width: unit( 274, 1280); }
	.Composing-image--02 { left: unit( 104, 1280); top: unit( 330, 1867); width: unit( 300, 1280); }
	.Composing-image--03 { left: unit( 146, 1280); top: unit(1550, 1867); width: unit( 258, 1280); }
	.Composing-image--04 { left: unit( 629, 1280); top: unit( 371, 1867); width: unit( 502, 1280); }
	.Composing-image--05 { left: unit( 116, 1280); top: unit( 724, 1867); width: unit(1271, 1280); }
	.Composing-image--06 { left: unit( 102, 1280); top: unit(1033, 1867); width: unit( 276, 1280); }
	.Composing-image--07 { left: unit( 129, 1280); top: unit(1108, 1867); width: unit( 363, 1280); }
	.Composing-image--08 { left: unit( 312, 1280); top: unit( 548, 1867); width: unit( 962, 1280); }
	.Composing-image--09 { left: unit(  82, 1280); top: unit( 852, 1867); width: unit( 336, 1280); }
	.Composing-image--10 { left: unit(  82, 1280); top: unit( 787, 1867); width: unit( 150, 1280); }
	.Composing-image--11 { left: unit( 153, 1280); top: unit(1066, 1867); width: unit( 176, 1280); }
	.Composing-image--12 { left: unit( 777, 1280); top: unit(1199, 1867); width: unit( 440, 1280); }
	.Composing-image--13 { left: unit( 359, 1280); top: unit(1459, 1867); width: unit( 249, 1280); }
}
