.Rubric {
	display: inline-block;
}

.Rubric-label {
	font-family: $font-extended;
	font-weight: 700;
	font-size: em(14, 18);
	padding-bottom: em(4, 14);
	background-image: linear-gradient(to bottom, transparent unit(19, 21), currentColor unit(19, 21));
	@include bp(medium) {
		font-size: em(15.5, 20);
		padding-bottom: em(4.5, 15.5);
		background-image: linear-gradient(to bottom, transparent unit(21.5, 23.5), currentColor unit(21.5, 23.5));
	}
	@include bp(larger) {
		font-size: em(17, 22);
		padding-bottom: em(5, 17);
		background-image: linear-gradient(to bottom, transparent unit(23, 25), currentColor unit(23, 25));
	}
}
