.Theme--black {
	background-color: $color-primary;
	color: $color-off-white;
}

.Theme--darkGray {
	background-color: $color-off-black;
	color: $color-light-gray;
}

.Theme--gray {
	background-color: $color-off-white;
	color: $color-primary;
}

.Theme--red {
	background-color: $color-secondary;
	color: $color-white;
}

.Theme--gold {
	background-color: $color-tertiary;
	color: $color-white;
}

.Theme--red,
.Theme--gold {

	::-moz-selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}

	::selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}
}

.Theme--white {
	background-color: $color-white;
	color: $color-primary;
}

.ThemeBlock--black {
	background-color: $color-primary;
	color: $color-off-white;
}

.ThemeBlock--darkGray {
	background-color: $color-off-black;
	color: $color-light-gray;
}

.ThemeBlock--gray {
	background-color: $color-off-white;
	color: $color-primary;
}

.ThemeBlock--red {
	background-color: $color-secondary;
	color: $color-white;
}

.ThemeBlock--gold {
	background-color: $color-tertiary;
	color: $color-white;
}

.ThemeBlock--red,
.ThemeBlock--gold {

	::-moz-selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}

	::selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}
}

.ThemeBlock--white {
	background-color: $color-white;
	color: $color-primary;
}

.ColorBlock--primary {
	background-color: $color-primary;
	color: $color-off-white;
}

.ColorBlock--secondary {
	background-color: $color-secondary;
	color: $color-white;

	::-moz-selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}

	::selection {
		color: $color-white;
		background-color: $color-primary;
		text-shadow: none;
	}
}

.ColorBlock--offBlack {
	background-color: $color-off-black;
	color: $color-light-gray;
}

.ColorBlock--darkGray {
	background-color: $color-dark-gray;
	color: $color-white;
}

.ColorBlock--mediumGray {
	background-color: $color-medium-gray;
	color: $color-primary;
}

.ColorBlock--lightGray {
	background-color: $color-light-gray;
	color: $color-primary;
}

.ColorBlock--offWhite {
	background-color: $color-off-white;
	color: $color-primary;
}

.ColorBlock--white {
	background-color: $color-white;
	color: $color-primary;
}

.Color--primary {
	color: $color-primary;
}

.Color--secondary {
	color: $color-secondary;
}

.Color--darkGray {
	color: $color-dark-gray;
}

.Color--mediumGray {
	color: $color-medium-gray;
}

.Color--lightGray {
	color: $color-light-gray;
}

.Color--offWhite {
	color: $color-off-white;
}

.Color--white {
	color: $color-white;
}
