.Editorial {
	@include bp(small) {
		@include clearfix;
	}
}

.Editorial-section {
	@include clearfix;
	padding: ms(2em) 0;
	@include bp(small) {
		padding-top: ms(3em);
		padding-bottom: ms(3em);
	}
	@include bp(large) {
		padding-top: ms(4em);
		padding-bottom: ms(4em);
	}

	.Editorial-block--figure.Editorial-block--full:first-child,
	.Editorial-block--video.Editorial-block--full:first-child {
		margin-top: ms(-2em);
		@include bp(small) {
			margin-top: ms(-3em);
		}
		@include bp(large) {
			margin-top: ms(-4em);
		}
	}

	.Page-header + .Editorial &.ThemeBlock--default:first-child,
	.Section.ThemeBlock--default + .Editorial &.ThemeBlock--default:first-child  {
		padding-top: 0;

		.Editorial-block--figure.Editorial-block--full:first-child,
		.Editorial-block--video.Editorial-block--full:first-child {
			margin-top: 0;
		}
	}
}

.Editorial-section.ThemeBlock--black,
.Editorial-section.ThemeBlock--gray,
.Editorial-section.ThemeBlock--red {
	padding-top: ms(2em);
	padding-bottom: ms(2em);
	@include bp(small) {
		padding-top: ms(3em);
		padding-bottom: ms(3em);
	}
	@include bp(large) {
		padding-top: ms(4.5em);
		padding-bottom: ms(4.5em);
	}

	.Editorial-block--figure.Editorial-block--full:first-child,
	.Editorial-block--video.Editorial-block--full:first-child {
		margin-top: ms(-2em);
		@include bp(small) {
			margin-top: ms(-3em);
		}
		@include bp(large) {
			margin-top: ms(-4.5em);
		}
	}
}

.Editorial-section.ThemeBlock--black {
	background-color: $color-primary;
	color: $color-off-white;
}

.Editorial-section.ThemeBlock--darkGray {
	background-color: $color-off-black;
	color: $color-light-gray;
}

.Editorial-section.ThemeBlock--gray {
	background-color: $color-off-white;
}

.Editorial-section.ThemeBlock--red {
	background-color: $color-secondary;
	color: $color-white;
}

.Editorial-block {

	& + & {
		margin-top: ms(1em);
		@include bp(large) {
			margin-top: ms(2em);
		}
	}
}

.Editorial-block--figure,
.Editorial-block--quote,
.Editorial-block--video {

	&.Editorial-block--center,
	&.Editorial-block--full,
	&.Editorial-block--grouped,
	&.Editorial-block--left,
	&.Editorial-block--right {

		& + .Editorial-block,
		.Editorial-block + & {
			margin-top: ms(2em);
			@include bp(small) {
				margin-top: ms(3em);
			}
			@include bp(large) {
				margin-top: ms(4em);
			}
		}
	}

	&.Editorial-block--grouped {

		.Editorial-block--grouped:first-child + & {
			@include bp(small) {
				margin-top: 0;
			}
		}
	}
}

.Editorial-block--aside.Editorial-block--pull {

	& + .Editorial-block--headline,
	& + .Editorial-block--text,
	& + .Editorial-block--quote {
		@include bp(medium) {
			margin-top: 0;
		}
	}
}

.Editorial-block--headline,
.Editorial-block--text {

	.Editorial-block.Editorial-block--pull + & {
		@include bp(medium) {
			margin-top: 0;
		}
	}

	& + & {
		margin-top: ms(1em);
		@include bp(large) {
			margin-top: ms(2em);
		}
	}
}

.Editorial-block--headline,
.Editorial-block--text--question {

	.Editorial-block--text + & {
		@include bp(small) {
			margin-top: ms(1.5em);
		}
		@include bp(large) {
			margin-top: ms(3em);
		}
	}

	&.Editorial-block + .Editorial-block--pull {
		margin-top: ms(1em);
		@include bp(large) {
			margin-top: ms(2em);
		}
	}
}

.Editorial-block--grouped {
	@include bp(small) {
		clear: both;
	}

	& + & {
		@include bp(small) {
			clear: none;
		}
	}

	.Editorial-component--quote {

		&.Editorial-component--left {
			margin-right: 0;
		}

		&.Editorial-component--right {
			margin-left: 0;
		}
	}

	.Editorial-component {
		@include bp(small) {
			margin-bottom: ms(3em);
			width: 50%;
			float: left;
		}
		@include bp(medium) {
			clear: none;
			width: 50%;
		}
		@include bp(large) {
			margin-bottom: ms(4em);
			width: 41.66666%;
		}
		@include bp(larger) {
			width: 40%;
		}
		@include bp(huge) {
			width: 41.66666%;
		}
		@include bp(ultra) {
			width: 35.71428%;
		}
	}

	.Editorial-component--left {
		@include bp(small) {
			margin-left: 0;
			margin-right: 0;
			padding-right: 0;
		}
		@include bp(large) {
			margin-left: 8.33333%;
		}
		@include bp(larger) {
			margin-left: 10%;
		}
		@include bp(huge) {
			margin-left: 8.33333%;
		}
		@include bp(ultra) {
			margin-left: 14.28571%;
		}
	}

	.Editorial-component--right {
		@include bp(small) {
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
		}
		@include bp(large) {
			margin-right: 8.33333%;
		}
		@include bp(larger) {
			margin-right: 10%;
		}
		@include bp(huge) {
			margin-right: 8.33333%;
		}
		@include bp(ultra) {
			margin-right: 14.28571%;
		}
	}

	& + & {

		& + .Editorial-block--center,
		& + .Editorial-block--full,
		& + .Editorial-block--left,
		& + .Editorial-block--right {
			@include bp(small) {
				clear: both;
			}
		}
	}
}

.Editorial-component--center {
	@include bp(small) {
		margin-left: 8.33333%;
		margin-right: 8.33333%;
	}
	@include bp(medium) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@include bp(large) {
		margin-left: 16.66666%;
		margin-right: 16.66666%;
	}
	@include bp(larger) {
		margin-left: 20%;
		margin-right: 20%;
	}
	@include bp(huge) {
		margin-left: 25%;
		margin-right: 25%;
	}
	@include bp(ultra) {
		margin-left: 28.57142%;
		margin-right: 28.57142%;
	}
}

.Editorial-component--full {

}

.Editorial-component--left {
	@include bp(small) {
		margin-right: 16.66666%;
	}
	@include bp(medium) {
		margin-right: 20%;
	}
	@include bp(large) {
		margin-right: 33.33333%;
	}
	@include bp(larger) {
		margin-right: 40%;
	}
	@include bp(huge) {
		margin-right: 50%;
	}
	@include bp(ultra) {
		margin-right: 57.14285%;
	}
}

.Editorial-component--right {
	@include bp(small) {
		margin-left: 16.66666%;
	}
	@include bp(medium) {
		margin-left: 20%;
	}
	@include bp(large) {
		margin-left: 33.33333%;
	}
	@include bp(larger) {
		margin-left: 40%;
	}
	@include bp(huge) {
		margin-left: 50%;
	}
	@include bp(ultra) {
		margin-left: 57.14285%;
	}
}

.Editorial-component--pull {
	@include bp(medium) {
		margin-bottom: ms(2em);
		width: 50%;
		clear: both;
	}
	@include bp(large) {
		width: 41.66666%
	}
	@include bp(larger) {
		width: 40%;
	}
	@include bp(huge) {
		width: 41.66666%;
	}
	@include bp(ultra) {
		width: 35.71428%;
	}

	&.Editorial-component--left {
		@include bp(medium) {
			float: left;
			margin-right: ms(0.5em);
		}
		@include bp(medium, larger) {
			padding-right: ms(0.5em);
		}
		@include bp(large) {
			clear: left;
		}
		@include bp(larger, huge) {
			padding-right: ms(1em);
		}
		@include bp(huge, ultra) {
			padding-right: 8.33333%;
		}
		@include bp(ultra) {
			padding-right: 7.14285%;
		}
	}

	&.Editorial-component--right {
		@include bp(medium) {
			float: right;
			margin-left: ms(0.5em);
		}
		@include bp(medium, larger) {
			padding-left: ms(0.5em);
		}
		@include bp(large) {
			clear: right;
		}
		@include bp(larger, huge) {
			padding-left: ms(1em);
		}
		@include bp(huge, ultra) {
			padding-left: 8.33333%;
		}
		@include bp(ultra) {
			padding-left: 7.14285%;
		}
	}

	&.Editorial-component--aside {
		@include bp(medium) {
			margin-bottom: ms(2em);
			width: 40%;
			clear: both;
		}
		@include bp(large) {
			width: 25%
		}
		@include bp(larger) {
			width: 20%;
		}
		@include bp(huge) {
			width: 25%;
		}
		@include bp(ultra) {
			width: 21.42857%;
		}
	}
}

.Editorial-component--figure,
.Editorial-component--video {

	&.Editorial-component--center,
	&.Editorial-component--full {
		@include bp(medium) {
			clear: both;
		}
	}

	&.Editorial-component--left,
	&.Editorial-component--right {
		@include bp(small) {
			margin-top: 0.5em;
		}
	}
}

.Editorial-component--aside,
.Editorial-component--headline,
.Editorial-component--text {
	padding: 0 ms(0.5em);
}

.Editorial-component--quote {

	&.Editorial-component--full {
		@include bp(large) {
			margin-left: 8.33333%;
			margin-right: 8.33333%;
		}
		@include bp(larger) {
			margin-left: 10%;
			margin-right: 10%;
		}
		@include bp(huge) {
			margin-left: 8.33333%;
			margin-right: 8.33333%;
		}
		@include bp(ultra) {
			margin-left: 14.28571%;
			margin-right: 14.28571%;
		}
	}
}

.Editorial-component--text--question {

	&.Editorial-component--center {
		text-align: center;
	}

	&.Editorial-component--left {
		@include bp(huge) {
			margin-left: 8.33333%;
		}
		@include bp(ultra) {
			margin-left: 7.14285%;
		}
	}

	&.Editorial-component--right {
		text-align: right;
		@include bp(huge) {
			margin-right: 8.33333%;
		}
		@include bp(ultra) {
			margin-right: 7.14285%;
		}
	}
}
