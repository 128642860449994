.Button {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	// Reset unusual Firefox-on-Android default style;
	// see https://github.com/necolas/normalize.css/issues/214
	background-image: none;
	border: none;
	white-space: nowrap;

	padding: 0;
	height: auto;

	font-family: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: center;

	transition-duration: 200ms;
	transition-property: color;
	transition-timing-function: ease-out;

	//user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	&,
	&:active,
	&:hover,
	&:focus,
	&:visited {
		outline: 0;
		text-decoration: none;
	}

	&:hover,
	&:active {
		color: $color-secondary;
	}

	&:active {
		transition-duration: 0;
	}

	&.is-disabled,
	&[disabled],
	fieldset[disabled] & {
		color: $color-medium-gray;
		cursor: not-allowed;
		// Future-proof disabling of clicks
		pointer-events: none;
		box-shadow: none;
	}
}

.Button--primary,
.Button--secondary,
.Button--ticket {
	border-style: solid;
	border-width: em(1, 14);
	padding: em(23 - 8.75, 14) (1em * $xl);
	height: em(48, 14);
	font-family: inherit;
	font-size: em(14, 18);
	font-style: normal;
	font-weight: normal;
	line-height: 1.25;
	transition-property: background-color, border-color, color;
	@include bp(medium) {
		border-width: em(1, 16);
		padding: em(26 - 10, 16) (1em * $xl);
		height: em(54, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		padding: em(29 - 10, 16) (1em * $xl);
		height: em(60, 16);
		font-size: em(16, 22);
	}

	&.Button--large {
		@include bp(null, medium) {
			font-size: em(18, 18);
		}
		@include bp(medium) {
			font-size: em(32, 20);
		}
		@include bp(larger) {
			font-size: em(32, 22);
		}
	}

	.Icon {
		top: 0.1em;
	}
}

.Button--primary {

	.Theme--black &,
	.ThemeBlock--black &,
	.ColorBlock--primary & {
		border-color: $color-off-white;
		background-color: $color-off-white;
		color: $color-primary;
	}

	&,
	.ThemeBlock--gray &,
	.ThemeBlock--white &,
	.ColorBlock--mediumGray &,
	.ColorBlock--offWhite &,
	.ColorBlock--white & {
		border-color: $color-primary;
		background-color: $color-primary;
		color: $color-white;
	}

	.Color--secondary & {
		border-color: $color-secondary;
		background-color: $color-secondary;
	}

	&:hover,
	&:active {
		background-color: transparentize($color-primary, 1);
		border-color: $color-secondary;
		color: $color-secondary;
	}
}

.Button--secondary,
.Button--ticket {

	.Theme--red &,
	.ThemeBlock--red &,
	.ColorBlock--secondary &,
	.Color--secondary & {

		&:hover,
		&:active {
			color: $color-primary;
		}
	}

	.ThemeBlock--black.Color--secondary &,
	.Theme--black .Color--secondary &,
	.ThemeBlock--black .Color--secondary &,
	.Color--secondary .ThemeBlock--black & {

		&:hover,
		&:active {
			color: $color-white;
		}
	}

	&:hover,
	&:active {
		color: $color-secondary;
	}
}

.Button--ticket {
	@include bp(medium) {
		padding: em(21 - 10, 16) (1em * $xl);
		height: em(44, 16);
	}
	@include bp(larger) {
		padding: em(22 - 10, 16) (1em * $xl);
		height: em(46, 16);
	}
	@include bp(huge) {
		padding: em(22 - 10, 16) (1em * $xl);
		height: em(46, 16);
	}
}

.Button--action {
	border-width: 0;
	padding: em(6, 14);
	height: em(48, 14);
	font-size: em(14, 18);
	line-height: 1.25;
	@include bp(medium) {
		padding: em(6, 16);
		height: em(48, 16);
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}

	.Button-inner {
		display: block;
		border-radius: em(18, 14);
		border-width: 0;
		padding: em(18 - 8.75, 14);
		@include bp(medium) {
			border-radius: em(18, 16);
			padding: em(8, 16);
		}
	}

	.Icon {
		top: 0;
	}

	.Icon--flag {
		height: 1.5em;
		margin: 0 -0.125em;
		top: -0.2em;
		width: 1.5em;
	}

	&.Button--action--narrow {
		@include bp(medium) {
			padding: em(6, 14);
			height: em(48, 14);
			font-size: em(14, 20);
		}
		@include bp(large) {
			padding-left: 0;
			padding-right: 0;
		}
		@include bp(larger) {
			padding-left: em(2, 14);
			padding-right: em(2, 14);
			font-size: em(14, 22);
		}

		.Button-inner {
			@include bp(medium) {
				border-radius: em(18, 14);
				padding: em(18 - 8.75, 14);
			}
		}
	}

	&.Button--primary,
	&.Button--secondary {

		&,
		&:hover,
		&:active {
			background-color: transparent;
			color: inherit;
		}
	}

	&.Button--primary {

		&,
		&:hover,
		&:active {

			.Button-inner {
				background-color: $color-secondary;
				color: $color-white;

				.Theme--red &,
				.ThemeBlock--red &,
				.ColorBlock--secondary &,
				.Color--secondary & {
					background-color: $color-primary;
				}
			}
		}
	}

	&.Button--secondary {

		.Button-inner {
			background-color: $color-white;
			border-style: solid;
			border-width: em(1, 14);
			padding: em(17 - 8.75, 14);
			@include bp(medium) {
				border-width: em(1, 16);
				padding: em(7, 16);
			}

			.Theme--black &,
			.ThemeBlock--black & {
				background-color: $color-primary;
			}

			.Theme--red &,
			.ThemeBlock--red & {
				background-color: $color-secondary;
			}
		}

		&,
		&:hover,
		&:active {

			.Button-inner {
				border-color: $color-secondary;
				color: $color-secondary;

				.Theme--red &,
				.ThemeBlock--red &,
				.ColorBlock--secondary &,
				.Color--secondary & {
					border-color: $color-primary;
					color: $color-primary;
				}
			}
		}
	}

	&.Button--social {

		&,
		&:hover,
		&:active {

			.Button-inner {
				background-color: $color-primary;
				color: $color-white;
				transition-duration: 200ms;
				transition-property: background-color;
				transition-timing-function: ease-out;

				.Theme--black &,
				.ThemeBlock--black &,
				.ColorBlock--primary &,
				.Color--primary & {
					background-color: $color-secondary;
				}
			}
		}

		&:hover,
		&:active {

			.Button-inner {
				background-color: $color-secondary;

				.Theme--black &,
				.ThemeBlock--black &,
				.ColorBlock--primary &,
				.Color--primary & {
					background-color: $color-secondary;
				}
			}
		}
	}

	&.Button--up {

		&,
		&:hover,
		&:active {

			.Button-inner {
				background-color: transparentize($color-primary, 0.4);
				color: $color-white;
				transition-duration: 200ms;
				transition-property: background-color;
				transition-timing-function: ease-out;

				.Theme--black &,
				.ThemeBlock--black &,
				.ColorBlock--primary &,
				.Color--primary & {
					background-color: transparentize($color-secondary, 0.4);
				}
			}
		}

		&:hover,
		&:active {

			.Button-inner {
				background-color: $color-primary;

				.Theme--black &,
				.ThemeBlock--black &,
				.ColorBlock--primary &,
				.Color--primary & {
					background-color: $color-secondary;
				}
			}
		}
	}
}

.Button--toggle {
	padding-left: 1em * $l;
	padding-right: 1em * $l + 1.25em;
	text-align: left;

	&.Button--primary {

		.isExpanded &,
		&:hover,
		&:active {
			background-color: $color-primary;
			border-color: $color-primary;
			color: $color-white;

			.Theme--black &,
			.ThemeBlock--black &,
			.ColorBlock--primary & {
				border-color: $color-off-white;
				background-color: $color-off-white;
				color: $color-primary;
			}
		}
	}

	.Icon {
		float: right;
		margin-right: -1.5em;

		.isExpanded & {
			transform: rotate(180deg);
			top: em(-1, 14);
			@include bp(medium) {
				top: em(-1, 16);
			}
		}
	}
}

.Button--extreme {
	position: relative;
	overflow: hidden;
	font-size: em(12, 18);
	line-height: 1.25;
	text-align: left;
	white-space: normal;
	@include bp(medium) {
		font-size: 1em;
	}

	.Button-label {

	}

	.Button-icon {
		position: absolute;
		top: -0.125em;
		display: block;
		color: $color-secondary;
		font-size: 4em;
		@include bp(medium) {
			font-size: 6.5em;
		}
	}
}

.Button--extreme--next {
	text-align: right;
	padding: 0.25em 3.5em 2.5em 0.5em;
	@include bp(medium) {
		padding: 0.5em 5em 4.75em 0.75em;
	}

	.Button-icon {
		top: auto;
		right: 0;
		bottom: -0.125em;
	}
}

.Button--extreme--prev {
	padding: 2.5em 0.5em 0.25em 3.5em;
	@include bp(medium) {
		padding: 4.75em 0.75em 0.5em 4.75em;
	}

	.Button-icon {
		left: 0;
	}
}
