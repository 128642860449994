.VideoPlayer {

}

.VideoPlayer-heading {
	padding: 0 ms(0.5em);
	text-align: center;
}

.VideoPlayer-player {
	@include bp(smaller, small) {
		margin-left: ms(-0.25em);
		margin-right: ms(-0.25em);
	}
	@include bp(small, medium) {
		margin-left: ms(-0.5em);
		margin-right: ms(-0.5em);
	}
	@include bp(medium, larger) {
		margin-left: ms(-1em);
		margin-right: ms(-1em);
	}
	@include bp(larger, ultra) {
		margin-left: ms(-1.5em);
		margin-right: ms(-1.5em);
	}
	@include bp(ultra) {
		padding-left: ms(0.5em);
		padding-right: ms(0.5em);
	}

	* + & {
		margin-top: ms(0.5em);
	}
}



.VideoPlayer-playlist {
	@include bp(ultra) {
		padding-left: ms(0.5em);
		padding-right: ms(0.5em);
	}
}
