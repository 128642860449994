$use-mediaqueries: true !default;

$min-width: (
	smaller: 22.5em,
	small: 30em,
	medium: 45em,
	large: 60em,
	larger: 75em,
	huge: 90em,
	ultra: 105em,
	giant: 120em
);

$max-width: (
	smaller: 22.4375em,
	small: 29.9375em,
	medium: 44.9375em,
	large: 59.9375em,
	larger: 74.9375em,
	huge: 89.9375em,
	ultra: 104.9375em,
	giant: 119.9375em
);

@mixin bp($min, $max: null) {
	$min-value: map-get($min-width, $min);
	$max-value: map-get($max-width, $max);

	@if $min-value {
		@if $max-value {
			@if $use-mediaqueries {
				@media (min-width: $min-value) and (max-width: $max-value) { @content; }
			}
			@else if $max-value > map-get($max-width, large) {
				@content;
			}
		}
		@else {
			@if $use-mediaqueries {
				@media (min-width: $min-value) { @content; }
			}
			@else if $min-value <= map-get($min-width, large) {
				@content;
			}
		}
	}
	@else if $max-value {
		@if $use-mediaqueries {
			@media (max-width: $max-value) { @content; }
		}
		@else if $max-value > map-get($max-width, large) {
			@content;
		}
	}
}
