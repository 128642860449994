html {
	height: 100%;
	box-sizing: border-box;
	//scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

::-moz-selection {
	color: $color-white;
	background-color: $color-secondary;
	text-shadow: none;
}

::selection {
	color: $color-white;
	background-color: $color-secondary;
	text-shadow: none;
}

body {
	height: 100%;

	&.isFullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
	}
}

table {
	border-collapse: collapse;
}

td,
th {
	padding: 0;
}

th {
	font-weight: inherit;
	text-align: inherit;
}
