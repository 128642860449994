.Figure {
	margin: 0 ms(0.5em);
}

.Figure-caption {
	text-align: center;

	.Figure-media + & {
		margin-top: ms(1em);
	}
}

.Figure--full {

	.Figure-media {
		@include bp(null, smaller) {
			margin-left: ms(-0.5em);
			margin-right: ms(-0.5em);
		}
		@include bp(smaller, small) {
			margin-left: ms(-0.75em);
			margin-right: ms(-0.75em);
		}
		@include bp(small, medium) {
			margin-left: ms(-1em);
			margin-right: ms(-1em);
		}
		@include bp(medium, larger) {
			margin-left: ms(-1.5em);
			margin-right: ms(-1.5em);
		}
		@include bp(larger, ultra) {
			margin-left: ms(-2em);
			margin-right: ms(-2em);
		}
	}
}

.Figure--poster {
	margin-left: 0;
	margin-right: 0;

	.Figure-media {
		position: relative;
		padding-bottom: 100%;
		@include bp(medium) {
			padding-bottom: 75%;
		}
		@include bp(large) {
			padding-bottom: 66.66666%;
		}
		@include bp(larger) {
			padding-bottom: unit(9, 16);
		}
	}

	.Figure-media-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.Figure-caption-inner {
		margin: 0 ms(0.5em);
	}
}

.Figure--stack {
	margin-left: 0;
	margin-right: 0;

	.Figure-media + .Figure-caption {
		margin-top: ms(0.5em);
	}
}
