.Nav {
	display: inline-block;
}

.Nav-title,
.Nav-items {
	display: inline-block;
	vertical-align: top;
}

button.Nav-title {
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	// Reset unusual Firefox-on-Android default style;
	// see https://github.com/necolas/normalize.css/issues/214
	background-image: none;
	border: none;
	padding: 0;
	font: inherit;
	text-align: inherit;
	outline: 0;
}

.Nav-item {
	margin-right: ms(0.5em);
	display: inline-block;
	vertical-align: top;
	@include bp(medium) {
		margin-right: ms(1em);
	}

	&:last-child {
		margin-right: 0;
	}
}

.Nav-label {
	font-size: em(18, 22);
	line-height: ms(1, 18 / 22);
}

.Nav-icon {
	display: inline-block;

	& + .Nav-label {
		display: inline-block;
		margin-left: 0.25em;
	}
}

.Nav-title {
	margin-right: ms(0.5em, 0.8);
	@include bp(medium) {
		margin-right: ms(1em, 0.8);
	}
}

.Nav-link {
	display: block;
}

.Nav--dropdown {
	@include bp(null, large) {
		position: relative;
		z-index: 1;
	}

	.Nav-title,
	.Nav-link {
		@include bp(null, medium) {
			padding-left: ms(0.5em);
			padding-right: ms(0.5em);
		}
	}

	.Nav-title {
		@include bp(null, medium) {
			position: relative;
			z-index: 1;
			margin-right: 0;
		}
	}

	.Nav-icon {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 1.5em;
		height: 1.5em;

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -0.3em;
			border-width: 0.3em;
			border-style: solid;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.Nav-items {
		@include bp(null, medium) {
			position: absolute;
			z-index: 0;
			top: em(-4.5, 18);
			left: 0;
			display: none;
			background-color: $color-white;
			box-shadow: 0 0 ms(0.5em) transparentize($color-primary, 0.8);
			padding-top: em(40.5, 18);
			padding-bottom: em(9, 18);
		}
	}

	.Nav-item {
		@include bp(null, medium) {
			margin-right: 0;
			display: block;
		}
	}

	&.isExpanded {

		.Nav-icon::after {
			transform: rotate(180deg);
			margin-top: -0.3em;
		}

		.Nav-items {
			@include bp(null, medium) {
				display: block;
			}
		}
	}
}

.Nav--secondary {

	.Nav-title,
	.Nav-link {
		padding-top: em(3, 18);
		padding-left: ms(0.5em);
		padding-right: ms(0.5em);
		padding-bottom: em(6, 18);
		@include bp(medium) {
			padding-top: em(1, 20);
			padding-bottom: em(5, 20);
		}
		@include bp(larger) {
			padding-top: 0;
			padding-bottom: em(3, 22);
		}
	}

	.Nav-title,
	.Nav-item {
		margin-right: 0;
	}

	.Nav-title {
		@include bp(medium) {
			display: none;
		}
	}

	.Nav-link {
		color: $color-medium-gray;
	}

	.isActive .Nav-link {
		color: $color-primary;
	}

	.Nav-label {
		position: relative;
		padding: 0.3em 0;
		font-family: $font-extended;
		font-size: em(17, 22);
		font-style: normal;
		font-weight: 700;
		line-height: 1.25;
		background-image: linear-gradient(to bottom, transparent unit(25, 27), currentColor unit(25, 27));
	}

}

.Nav--small {

	.Nav-link {

	}

	.Nav-label {
		position: relative;
		top: em(-2, 12);
		font-size: em(12, 18);
		line-height: 1.25;
		@include bp(medium) {
			top: em(-3, 14);
			font-size: em(14, 20);
		}
		@include bp(larger) {
			font-size: em(14, 22);
		}
	}
}

.Nav--social {

	.Nav-item {
		margin-right: 0;
	}
}
