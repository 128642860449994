.Details {

}

.Details-item {

	* + & {
		margin-top: ms(0.75em);
	}
}

.Details-label,
.Details-value {
	display: block;
	font-size: em(15, 18);
	@include bp(medium) {
		font-size: em(16, 20);
	}
	@include bp(larger) {
		font-size: em(16, 22);
	}
}

.Details-label {
	color: $color-light-gray;
}

.Details-value {

}

.Details--horizontal {
	margin: 0 ms(-0.5em);
	@include clearfix(left);

	.Details-item {
		margin-top: 0;
		margin-bottom: ms(0.75em);
		padding: 0 ms(0.5em);
		float: left;
		width: 50%;
		@include bp(small) {
			width: 33.33333%;
		}
	}
}
