// Scale
$scale-base: 1.5;

// Units
$xs  : 0.375;
$s   : 0.75;
$m   : 1;
$l   : 1.5;
$xl  : 2.25;
$xxl : 3;

// Colors
$color-primary     : rgb(0,0,0);
$color-secondary   : rgb(255,0,0);
$color-tertiary    : rgb(146,122,73);
$color-white       : rgb(255,255,255);
$color-dark-gray   : lighten($color-primary, 30%);
$color-medium-gray : lighten($color-primary, 50%);
$color-light-gray  : lighten($color-primary, 70%);
$color-off-white   : rgb(243,243,244);
$color-off-black   : lighten($color-primary, 15%);

// Fonts
$font-primary      : "GTAmerica", Helvetica, Arial, sans-serif;
$font-compressed   : "GTAmericaCompressed", Helvetica, Arial, sans-serif;
$font-extended     : "GTAmericaExtended", Helvetica, Arial, sans-serif;
$font-expanded     : "GTAmericaExpanded", Helvetica, Arial, sans-serif;

// Mixins
@import "mixins/all";

// Vendor
@import "vendor/normalize";

// Globals
@import "globals/animations";
@import "globals/color";
@import "globals/reset";
@import "globals/typography";

// Objects
@import "objects/logo";
@import "objects/icon";
@import "objects/link";
@import "objects/button";
@import "objects/nav";
@import "objects/rubric";
@import "objects/meta";
@import "objects/heading";
@import "objects/excerpt";
@import "objects/copy";
@import "objects/credit";
@import "objects/caption";
@import "objects/responsive-media";
@import "objects/poster";
@import "objects/track";
@import "objects/details";
@import "objects/option";

// Modules
@import "modules/masthead";
@import "modules/menu";
@import "modules/section-nav";
@import "modules/filter";
@import "modules/breadcrumb";
@import "modules/billboard";
@import "modules/hero";
@import "modules/teaser";
@import "modules/lead";
@import "modules/figure";
@import "modules/quote";
@import "modules/stack";
@import "modules/band-members";
@import "modules/event";
@import "modules/distributors";
@import "modules/track-list";
@import "modules/video-player";
@import "modules/play-tile";
@import "modules/song";
@import "modules/register";
@import "modules/composing";
@import "modules/chronicle";
@import "modules/campaign";
@import "modules/sequence";
@import "modules/gallery";
@import "modules/gallery-slide";
@import "modules/form";
@import "modules/footer";

// Layouts
@import "layouts/root";
@import "layouts/container";
@import "layouts/grid";
@import "layouts/section";
@import "layouts/editorial";
@import "layouts/page";
@import "layouts/release-details";
@import "layouts/song-details";
@import "layouts/paginated-list";
