.Gallery {
	position: relative;
	width: 100%;
	height: 100%;
}

.Gallery-inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.Gallery-body {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-bottom: 3.75em;
	white-space: nowrap;
	letter-spacing: -0.31em; /* webkit: collapse white-space between units */
	text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

	/*
		Opera as of 12 on Windows needs word-spacing.
		The ".opera-only" selector is used to prevent actual prefocus styling
		and is not required in markup.
	*/
	.opera-only :-o-prefocus,
	& {
		word-spacing: -0.43em;
	}
}

.Gallery-item {
	position: relative;
	width: 100%;
	height: 100%;

	.Gallery-body > & {
		display: inline-block;
		letter-spacing: normal;
		word-spacing: normal;
		vertical-align: top;
		text-rendering: auto;
		white-space: normal;
		box-sizing: border-box;
	}
}

.Gallery-control {
	position: absolute;
	z-index: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	background-image: none;
	border: none;
	outline: 0;
	color: inherit;
	font-family: inherit;
	font-size: 1.5em;
	font-weight: inherit;
	@include bp(medium) {
		font-size: 3em;
	}

	.Button {
		font-size: inherit;
		height: 2em;
	}

	&:hover,
	&:active {

		.Button {
			color: $color-secondary;
		}
	}
}

.Gallery-control--close {
	z-index: 1;
	top: 0.25em;
	right: 0.25em;

	@include bp(smaller) {
		top: 0;
		right: 0;
	}
}

.Gallery-control--next,
.Gallery-control--prev {
	top: 0;
	bottom: 0;
	width: 33.33333%;

	.Gallery-control-button {
		position: absolute;
		top: 50%;
		margin-top: -1em;

		@include bp(smaller) {
			margin-top: -1em;
		}
	}
}

.Gallery-control--next {
	right: 0;

	.Gallery-control-button {
		right: 0;
		@include bp(null, smaller) {
			right: 0.25em;
		}
	}

	.Gallery.isLast & {
		display: none;
	}
}

.Gallery-control--prev {
	left: 0;

	.Gallery-control-button {
		left: 0;
		@include bp(null, smaller) {
			left: 0.25em;
		}
	}

	.Gallery.isFirst & {
		display: none;
	}
}
