.Teaser {
	margin: 0 ms(1em);
	@include bp(large) {
		margin-left: ms(1.5em);
		margin-right: ms(1.5em);
	}
}

.Teaser-visual {
	display: block;
}

.Teaser-body {

	.Teaser-visual + & {
		margin-top: ms(0.5em);
		@include bp(large) {
			margin-top: ms(1em);
		}
	}
}

.Teaser-heading {
	display: block;

	& + .Teaser-meta {
		margin-top: ms(0.125em);
	}
}

.Teaser-cta {
	display: block;
}

.Teaser--article,
.Teaser--product {
	@include bp(large) {
		margin-left: 0;
		margin-right: 0;
	}

	.Teaser-visual,
	.Teaser-meta,
	.Teaser-heading,
	.Teaser-excerpt,
	.Teaser-cta {
		@include bp(large) {
			margin-left: 16.66666%;
			margin-right: 0;
			padding-left: ms(0.5em);
			padding-right: ms(0.5em);
		}
	}

	.Teaser-visual {
		@include bp(large) {
			margin-right: 16.66666%;
		}

		& + .Teaser-body {
			margin-top: ms(0.625em);
			@include bp(large) {
				margin-top: ms(1.25em);
			}
		}
	}

	.Teaser-meta,
	.Teaser-heading,
	.Teaser-excerpt,
	.Teaser-cta {
		@include bp(large) {
			margin-right: 16.66666%;
		}
	}

	.Teaser-meta {

		& + .Teaser-heading {
			margin-top: ms(0.25em);
			@include bp(large) {
				margin-top: ms(0.75em);
			}
		}
	}

	.Teaser-heading {

		& + .Teaser-excerpt {
			margin-top: ms(0.75em);
			@include bp(large) {
				margin-top: ms(1em);
			}
		}
	}
}

.Teaser--article {

	.Teaser-visual {
		@include bp(huge) {
			margin-right: 33.33333%;
		}
	}

	.Teaser-excerpt,
	.Teaser-cta {
		@include bp(huge) {
			margin-right: 33.33333%;
		}
	}

	.Teaser-cta {
		margin-top: ms(-0.125em);
		@include bp(small) {
			margin-top: ms(-0.25em);
		}
	}
}

.Teaser--interview {

	.Teaser-visual {
		@include bp(larger) {
			margin-right: 16.66666%;
		}
	}

	.Teaser-heading {
		@include bp(large) {
			margin-right: 0;
		}
	}

	.Teaser-cta {
		margin-top: 0;
	}

	&.Teaser--feature {
		@include bp(medium) {
			margin-left: 0;
			margin-right: 0;
		}

		.Teaser-visual,
		.Teaser-meta,
		.Teaser-heading,
		.Teaser-excerpt,
		.Teaser-cta {
			@include bp(medium) {
				margin-left: 0;
				margin-right: 0;
				padding-left: ms(0.5em);
				padding-right: ms(0.5em);
			}
		}

		.Teaser-visual {
			@include bp(medium) {
				margin-left: 16.66666%;
				margin-right: 16.66666%;
			}
			@include bp(large) {
				margin-left: 25%;
				margin-right: 25%;
			}

		}

		.Teaser-visual + .Teaser-body .Teaser-meta {
			@include bp(large) {
				float: left;
				margin-top: ms(-1.85em);
			}
			@include bp(larger) {
				margin-top: ms(-1.8em);
			}
		}

		.Teaser-body {
			@include bp(medium) {
				text-align: center;
				margin-left: 8.33333%;
				margin-right: 8.33333%;
			}
			@include bp(large) {
				margin-left: 16.66666%;
				margin-right: 16.66666%;
			}
			@include bp(larger) {
				margin-left: 25%;
				margin-right: 25%;
			}
		}
	}
}

.Teaser--recommendation {

	.Teaser-visual {
		margin-right: 33.33333%;
	}

	.Teaser-meta,
	.Teaser-heading {
		@include bp(null, large) {
			margin-left: 0;
		}
	}

	&.Teaser--feature {
		@include bp(medium) {
			@include clearfix;
			margin-left: 0;
			margin-right: 0;
		}

		.Teaser-visual {
			margin-left: 16.66666%;
			margin-right: 16.66666%;
			@include bp(medium) {
				float: left;
				margin-top: 0.5em;
				margin-left: 0;
				margin-right: 0;
				padding-left: ms(0.5em);
				padding-right: ms(0.5em);
				width: 33.33333%;
			}
			@include bp(larger) {
				margin-left: 8.33333%;
				margin-right: 8.33333%;
				width: 25%;
			}
		}

		.Teaser-visual + .Teaser-body {
			@include bp(null, medium) {
				margin-top: ms(2em);
			}
		}

		.Teaser-body {
			@include bp(medium) {
				margin-top: 0;
				overflow: hidden;
			}
			@include bp(huge) {
				margin-right: 8.33333%;
			}
		}

		.Teaser-meta,
		.Teaser-heading,
		.Teaser-excerpt,
		.Teaser-cta {
			@include bp(medium) {
				padding-left: ms(0.5em);
				padding-right: ms(0.5em);
			}
			@include bp(large) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		* + .Teaser-heading {
			@include bp(medium) {
				margin-top: ms(0.75em);
			}
		}

		* + .Teaser-excerpt {
			@include bp(medium) {
				margin-top: ms(1.5em);
			}
		}

		* + .Teaser-cta {
			@include bp(medium) {
				margin-top: ms(0.25em);
			}
		}
	}
}

.Teaser--release {
	text-align: center;
	@include bp(null, medium) {
		margin-left: ms(0.75em);
		margin-right: ms(0.75em);
	}
	@include bp(medium, large) {
		margin-left: ms(0.5em);
		margin-right: ms(0.5em);
	}
}

.Teaser--product {
	position: relative;
	z-index: 0;

	.Teaser-visual {
		position: relative;
		padding-top: ms(0.5em);
		@include bp(smaller) {
			padding-top: ms(0.75em);
		}
		@include bp(small) {
			padding-top: ms(1em);
		}
		@include bp(medium) {

		}
		@include bp(large) {
			padding-top: 16.66666%;
		}

		&::before {
			content: "";
			display: block;
			z-index: -1;
			position: absolute;
			top: 0;
			left: ms(-1em);
			right: ms(-1em);
			bottom: ms(-2.625em);
			margin: 0 ms(0.5em);
			background-color: $color-secondary;
			@include bp(smaller) {
				bottom: ms(-2.875em);
			}
			@include bp(small) {
				bottom: ms(-3.125em);
			}
			@include bp(small, medium) {
				left: ms(-1.5em);
				right: ms(-1.5em);
				margin-left: ms(0.75em);
				margin-right: ms(0.75em);
			}
			@include bp(large) {
				left: -25%;
				right: -25%;
				bottom: ms(-3.75em);
			}
			@include bp(large) {
				bottom: ms(-3.625em);
			}
		}

		& + .Teaser-body {
			margin-top: ms(0.5em);
			@include bp(smaller) {
				margin-top: ms(0.75em);
			}
			@include bp(small) {
				margin-top: ms(1em);
			}
		}
	}

	.Teaser-excerpt {

		& + .Teaser-cta {
			margin-top: ms(1em);
			@include bp(medium) {
				margin-top: ms(1em);
			}
			@include bp(large) {
				margin-top: ms(1.25em);
			}
		}
	}
}

.Teaser--product--billboard {

	.Teaser-visual {
		padding-top: 0;

		&::before {
			display: none;
		}
	}
}

.Teaser--stacked {
	margin: 0 ms(0.5em);

	.Stack-unit:nth-child(3n+2) & {
		@include bp(medium) {
			display: flex;
			flex-direction: column-reverse;
		}

		.Teaser-body {
			@include bp(medium) {
				margin-top: 0;
				margin-bottom: ms(0.5em);
			}
			@include bp(large) {
				margin-bottom: ms(1em);
			}
		}
	}
}

.Teaser--tour {
	margin-left: ms(0.5em);
	margin-right: ms(0.5em);
	@include bp(medium) {
		margin-left: ms(1.5em);
		margin-right: ms(1.5em);
	}
	@include bp(large) {
		margin-left: ms(2em);
		margin-right: ms(2em);
	}
	@include bp(larger) {
		margin-left: ms(2.5em);
		margin-right: ms(2.5em);
	}

	.Teaser-visual {

		& + .Teaser-body {
			margin-top: ms(0.75em);
			@include bp(large) {
				margin-top: ms(1em);
			}
		}
	}

	.Teaser-meta,
	.Teaser-heading,
	.Teaser-excerpt,
	.Teaser-cta {

		& + * {
			margin-top: ms(0.25em);
			@include bp(large) {
				margin-top: ms(0.5em);
			}
		}
	}
}
