.PlayTile {
	display: block;
	padding-bottom: ms(2em);
	@include bp(larger) {
		padding-bottom: ms(3em);
	}
}

.PlayTile-body {
	position: relative;
	padding: 0 ms(0.5em);
	text-align: center;

	* + & {
		padding-top: ms(1em);
	}
}

.PlayTile-button {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
